import React, { useContext, useState, } from 'react';
import { PopupButton } from '@typeform/embed-react';
import { store } from '../../../utils/store';
import Crescent from '../../../api/crescent';
import { ThemeContext } from '../../../constants/themes';
import Type from '../../../components/atoms/Type';
import Loader from '../../../components/atoms/Loader';
import style from './index.module.css';

const crescent = new Crescent();

const AUTO_CLOSE_BUFFER = 100;

const Step = ({ activeStep, step, children, visible = (step) => step === activeStep, }) => {
  return (
    <div className={style.step} style={visible(step) ? undefined : { opacity: 0.1, pointerEvents: 'none', }}>
      <div className={style.stepNumber}>
        <Type style={{ fontWeight: 600, margin: 0, }}>{step}.</Type>
      </div>
      {children}
    </div>
  )
}

const Survey = ({ mission, onMissionComplete, }) => {
  const theme = useContext(ThemeContext);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  
  const [activeStep] = useState(1);

  const [processingMissionComplete, setProcessingMissionComplete] = useState(false);

  const _onMissionComplete = (responseId) => {
    setTimeout(() => {
      setProcessingMissionComplete(true);
      // If actually on the last step...
      if (activeStep === 1) {
        crescent.postSurveyComplete(responseId).then(res => {
          console.log('post survey res', res);
          if (!!res && !res.error) {
            onMissionComplete(mission.task_id, mission.task_type, res);
            setProcessingMissionComplete(false);
          } else {
            onMissionComplete(mission.task_id, mission.task_type, { error: res?.err });
            setProcessingMissionComplete(false);
          }
        });
      }
    }, AUTO_CLOSE_BUFFER);
  }

  return (
    <>
      <Step activeStep={activeStep} step={1}>
        <Type type="h2" component="h2" style={{ marginBottom: '12px', }}>Complete the following short survey</Type>
        <br />
        <PopupButton
          autoClose={AUTO_CLOSE_BUFFER}
          // Uncomment to test dumby survey
          // id="XL7X8o6f"
          id={state.survey_task_typeform_id}
          className={style.typeformLaunchButton}
          style={{ color: theme.text, backgroundColor: theme.primary, }}
          onSubmit={(data) => {
            console.log(data);
            _onMissionComplete(data.responseId);
          }}
        >
          Open Survey
        </PopupButton>
        <br />
        <br />
        {processingMissionComplete && <Loader />}
        <br />
        <br />
      </Step>
    </>
  );
}

export default Survey;