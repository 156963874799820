import React, { useEffect, } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

const Modal = ({ children, show, style = undefined }) => {
  useEffect(() => {
    if (show) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = 'relative';
      document.body.style.top = undefined;
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [ show, ]);

  if (!show) {
    return null;
  } else {
    return (
      <>
        <div className={styles.modal_background}>
        </div>
        <div className={styles.modal_inner_background}>
          <div className={styles.modal} style={style}>
            {children}
          </div>
        </div>
      </>
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired
}

export default Modal;
