import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import TweetEmbed from 'react-tweet-embed';
import { store } from '../../../utils/store';
import Loader from '../../../components/atoms/Loader';
import Type from '../../../components/atoms/Type';
import TextInput from '../../../components/atoms/TextInput';
import Button from '../../../components/atoms/Button';
import { ReactComponent as PointIcon } from '../../../assets/img/point.svg';
import emojiNameScreenshot from '../../../assets/img/emoji-name-screenshot.png';
import Crescent from '../../../api/crescent';
import style from './index.module.css';

const crescent = new Crescent();

const getRandomSpinString = () => {
  const spinStrings = [
    '🔄',
    'Here we go',
    'Let\'s go',
    'Tryna win some points',
    'I believe',
    'What do I have to lose',
    'Please please pleaseeee',
    'I FEEL A WIN BABY',
    'I\'m on a roll',
    'I\'m gonna win',
    'SPINNNN',
    'SPINNING',
    'SPINNING SPINNING SPINNING',
    'Please give me points lol',
    'I spent all my points on this',
    'I got this',
    'I got this!',
    'Just give me the points',
    'Here. We. Go.',
    'Hot hand here we go',
    'Literally can\'t lose',
    'I\'m swear I\'m gonna win',
    'Pls win',
    'Lucky 🌙 please',
    'Lucky 🌙 please!',
  ];
  return spinStrings[Math.floor(Math.random() * spinStrings.length)];
};

const REFRESH_POST_COMPLETION_DELAY = 2000;

const Step = ({ activeStep, step, children, visible = (step) => step === activeStep, }) => {
  return (
    <div className={style.step} style={visible(step) ? undefined : { opacity: 0.1, pointerEvents: 'none', }}>
      <div className={style.stepNumber}>
        <Type style={{ fontWeight: 600, margin: 0, }}>{step}.</Type>
      </div>
      {children}
    </div>
  )
}

const TwitterSlot = ({ mission, onMissionComplete, }) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const stepTwoRef = useRef(null);

  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    if (!!state.twitter_handle && activeStep !== 2) {
      setActiveStep(2);
      if (stepTwoRef.current) {
        stepTwoRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ state.twitter_handle, activeStep, ]);

  const [processingMissionComplete, setProcessingMissionComplete] = useState(false);

  const _onMissionComplete = () => {
    setProcessingMissionComplete(true);
    // If actually on the last step...
    if (activeStep === 2) {
      // Pass mission-specific on-complete data
      // First check if there's already a pending check for this mission in async_task_status
      // If there is, just reload things by "completing" the mission
      if (state.async_task_status[mission.id] && state.async_task_status[mission.id].status === 'pending') {
        setTimeout(() => {
          onMissionComplete(mission.task_id, mission.task_type);
          setProcessingMissionComplete(false);
        }, REFRESH_POST_COMPLETION_DELAY);
      }
      crescent.initiateAsyncCompletion(mission.task_id).then(res => {
        console.log('slot check response', res);
        if (res && !res.error) {
          try {
            setTimeout(() => {
              onMissionComplete(mission.task_id, mission.task_type);
              setProcessingMissionComplete(false);
            }, REFRESH_POST_COMPLETION_DELAY);
          } catch (err) {
            console.log(err);
            onMissionComplete(mission.task_id, mission.task_type, { error: err });
            setProcessingMissionComplete(false);
          }
        } else {
          console.log(res?.err);
          onMissionComplete(mission.task_id, mission.task_type, { error: res?.err });
          setProcessingMissionComplete(false);
        }
      });
    }
  }

  const [twitterHandle, setTwitterHandle] = useState('');

  const onTwitterHandleChange = useCallback((e) => {
    setTwitterHandle(e.target.value);
  }, []);

  const [submittingTwitterHandle, setSubmittingTwitterHandle] = useState(false);

  const submitTwitterHandle = () => {
    setSubmittingTwitterHandle(true);
    if (twitterHandle && twitterHandle.length > 3) {
      let _twitterHandle = twitterHandle.trim().replace(/[@&/\\#,+()$~%.'":*?<>{}]/g, '');
      crescent.registerTwitterHandle(_twitterHandle).then((res) => {
        if (res && !res.error) {
          dispatch({
            type: 'SET_TWITTER_HANDLE',
            payload: twitterHandle,
          });
          setActiveStep(2);
          setSubmittingTwitterHandle(false);
        } else {
          // TODO: Handle error state where twitter handle submission fails
          // i.e. twitter handle already exists?
        }
      });
    }
  }

  const tweetId = mission.data?.tweet_id;

  return (
    <>
      <Step activeStep={activeStep} step={1}>
        <Type type="h2" component="h2" style={{ marginBottom: '12px', }}>Enter your Twitter account handle</Type>
        <br />
        <TextInput
          label="Twitter Username"
          name="twitterHandle"
          placeholder="username"
          format="twitterHandle"
          width={'100%'}
          value={state.twitter_handle ? `@${state.twitter_handle}` : twitterHandle}
          onChange={onTwitterHandleChange}
        />
        <br />
        <Button type="secondary" value="Next" onClick={submitTwitterHandle} disabled={submittingTwitterHandle || !!state.twitter_handle} />
      </Step>
      <Step activeStep={activeStep} step={2}>
        <div ref={stepTwoRef} />
        <br />
        <Type type="h2" component="h2" style={{ marginTop: '-8px', marginBottom: '8px', fontWeight: '600', whiteSpace: 'nowrap', }}>Wager <PointIcon width={30} height={30} className={style.point} /> <strong>25</strong> for a chance to win 🎉🎰</Type>
        <br />
        <Type style={{ marginTop: '-8px', marginBottom: '8px', fontWeight: '600', whiteSpace: 'nowrap', }}>You have: <PointIcon width={22} height={22} className={style.point} /> <strong>{state.points}</strong></Type>
        {state.points && state.points >= 25
          ? (
            <>
              <Type>Spin the Twitter Slot machine by tweeting <a href={`https://twitter.com/${state.slot_handle}`}>@{state.slot_handle}</a> with #spin.</Type>
              <Type style={{ marginTop: '-8px', marginBottom: '8px', whiteSpace: 'nowrap', }}>You can win up to <PointIcon width={22} height={22} className={style.point} /> <strong>500</strong> on every wager 👀</Type>
              <br />
              <Type>Tap "Spin!" below to continue.</Type>
            </>
          ) : (
            <Type>Sorry, you need more points to spin the Twitter Slot machine! Complete other missions to earn points.</Type>
          )}
        <br />
        {processingMissionComplete && (
          <div className={style.processingMsg}>
            <div style={{ display: 'inline-block', marginRight: '8px', }}>
              <Loader />
            </div>
            <Type>Processing...</Type>
          </div>
        )}
        <br />
        {!state.points || (state.points && state.points < 25) && (
          <Button type="secondary" value="Back to All Missions" href={window.location.origin} />
        )}
        <Button type="primary" value="Spin!" href={`https://twitter.com/intent/tweet?text=.@${state.slot_handle}${encodeURIComponent(' ' + getRandomSpinString())}...%20%23spin%21`} disabled={processingMissionComplete || !state.points || (state.points < 25)} />
      </Step>
    </>
  );
}

export default TwitterSlot;