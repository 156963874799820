export default class Auth {
  constructor() {
    this.AUTH_URL = process.env.REACT_APP_AUTH_URL;
    this.AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
    this.AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  isAuthenticated = () => {
    const authInfo = localStorage.getItem("authInfo");
    try {
      const parsed = JSON.parse(authInfo);
      if (!parsed || !parsed.token || parsed.token === '') {
        return false;
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  getAuthInfo = async () => {
    const isAuthenticated = await this.isAuthenticated();
    if (isAuthenticated) {
      return JSON.parse(localStorage.getItem("authInfo"));
    } else {
      // TODO: Redirect to sign in page
      return null;
    }
  }
  
  // Gets the error message from an API response to see if
  // the access token needs to be refreshed.
  // response: Promise<{ error: ... }>
  isAccessTokenInvalid = async (response) => {
    response.then(res => {
      if (res.error && res.error === "invalid_auth_token") {
        this.signOut();
      } else {
        return false;
      }
    });
  }

  storeAuthInfo = async (authToken, phoneNumber) => {
    try {
      const authInfo = {
        token: authToken,
        phoneNumber,
      };
      localStorage.setItem("authInfo", JSON.stringify(authInfo));
      return authInfo;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  signOut = () => {
    try {
      localStorage.removeItem("authInfo");
      return true;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
}
