import React, { useContext, useState, } from 'react';
import { store } from '../../../utils/store';
import Type from '../../../components/atoms/Type';
import Button from '../../../components/atoms/Button';
import CopyToClipboardField from '../../../components/atoms/CopyToClipboardField';
import style from './index.module.css';

const Referral = ({ mission, onMissionComplete, }) => {
  const globalState = useContext(store);
  const { state, } = globalState;

  const [activeStep, setActiveStep] = useState(1);

  const _onMissionComplete = () => {
    // If actually on the last step...
    if (activeStep === 1) {
      // Pass mission-specific on-complete data
      const missionData = null;
      onMissionComplete(mission.task_id, mission.task_type, missionData);
    }
  }

  const referralLink = `${window.origin}/?ref=${state.referral_id}`;

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Join the Crescent Waitlist",
          text: `Join Crescent’s waitlist to earn 100x more interest than your old bank. Complete the referral mission to unlock even higher interest! 💜🌙`,
          url: referralLink,
        })
        .then(() => {
          console.log('Shared');
          _onMissionComplete();
        })
        .catch(error => {
          console.error('Cancelled Sharing', error);
        });
    }
  }

  const Step = ({ step, children, visible = (step) => step === activeStep, }) => {
    return (
      <div className={style.step} style={visible(step) ? undefined : { opacity: 0.1, pointerEvents: 'none', }}>
        <div className={style.stepNumber}>
          <Type style={{ fontWeight: 600, margin: 0, }}>{step}.</Type>
        </div>
        {children}
      </div>
    )
  }

  return (
    <>
      <Step step={1}>
        <Type type="h2" component="h2" style={{ marginBottom: '12px', }}>Share your referral link</Type>
        {navigator.share
          ? (
          <>
            <Type>You'll be rewarded every time someone joins the waitlist using your referral link.</Type>
            <br />
            <Button type="primary" value="Share ✉️" onClick={share} />
          </>
        ) : (
          <>
            <Type>Copy your unique referral link and share it! You'll be rewarded every time someone joins the waitlist using your link.</Type>
            <br />
            <CopyToClipboardField value={referralLink} />
            <br />
            <Button type="primary" value="Done" onClick={_onMissionComplete} />
          </>
        )}
      </Step>
    </>
  );
}

export default Referral;