import React, { useContext, useEffect, useRef, } from 'react';
import { ThemeContext } from '../../../constants/themes';
import Type from '../Type';
import masks from './masks';
import cssModules from './index.module.css';

const TextInput = ({ name, label, placeholder, value, onChange, autocomplete = 'off', size = undefined, width = 60, format = undefined, min = undefined, max = undefined, alignRight, style, disabled = false, prepend = '', shouldFocusOnMount = false, }) => {
  const theme = useContext(ThemeContext);
  const id = `${format}-${name}`;
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current && shouldFocusOnMount) {
      inputRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ shouldFocusOnMount, inputRef?.current, ]);

  const styles = {
    label: {
      fontSize: 'calc(14px + 0.375vw)',
      fontWeight: 600,
      color: theme.text,
    },
  };

  const renderInput = () => {
    let _onChange = onChange;
    switch (format) {
      case 'number':
        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            type="number"
            min={min}
            max={max}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
      case 'phoneNumber':
        _onChange = (e) => {
          e.target.value = masks.phoneNumber(e.target.value);
          onChange(e);
        }

        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
      case 'twitterHandle':
        _onChange = (e) => {
          e.target.value = masks.twitterHandle(e.target.value);
          onChange(e);
        }

        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
      case 'instagramHandle':
        _onChange = (e) => {
          e.target.value = masks.instagramHandle(e.target.value);
          onChange(e);
        }

        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
      case 'money':
        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            type="number"
            step="0.01"
            min={min}
            max={max}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
      case 'search':
        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            type="search"
            size={size || value?.length || placeholder?.length || undefined}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
      default:
        return (
          <input
            id={id}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            size={size || value?.length || placeholder?.length || undefined}
            disabled={disabled}
            onChange={_onChange}
            autoComplete={autocomplete}
          />
        );
    }
  }

  return (
    <div
      onClick={() => inputRef.current.focus()}
      className={cssModules.wrapper}
      style={{ width, textAlign: alignRight ? 'right' : 'left', ...style }}
    >
      {label && <Type style={styles.label} component="label">{label}</Type>}
      <div className={cssModules.input}>
        {prepend && <span className={cssModules.prepend}>{prepend}</span>}
        {renderInput()}
      </div>
    </div>
  );
}

export default TextInput;
