import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../constants/themes';
import cssModules from './index.module.css';

const Button = ({ disabled, children, value, type = 'primary', size = "normal", onClick, style, href, openInNewTab = false, isProcessing }) => {
  const theme = useContext(ThemeContext);

  const onPress = (input) => {
    if (!disabled && onClick !== undefined) {
      onClick(input);
    }
  }

  if (disabled || isProcessing) {
    style = {
      ...style,
      opacity: 0.4,
      cursor: 'default',
      backgroundColor: theme.verySubtle,
    }
  }

  const getTypeSpecificStyles = () => {
    switch (type) {
      case 'primary':
        return {
          color: theme.text,
          backgroundColor: theme.primary,
          textDecoration: 'none',
          borderRadius: '100px',
        };
      case 'secondary':
        return {
          color: theme.text,
          backgroundColor: theme.verySubtle,
          textDecoration: 'none',
          borderRadius: '100px',
        };
      case 'card':
        return {
          color: theme.text,
          backgroundColor: theme.verySubtle,
          textDecoration: 'none',
          borderRadius: '10px',
        };
      case 'danger':
        return {
          color: theme.danger,
          backgroundColor: theme.dangerAccent,
          textDecoration: 'none',
          borderRadius: '100px',
        };
      case 'text':
        return {
          color: theme.primary,
          backgroundColor: 'transparent',
          textDecoration: 'none',
          borderRadius: '100px',
        };
      case 'wrapper':
        return {
          backgroundColor: 'transparent'
        };
      default:
        return {};
    }
  }

  const getSizeSpecificStyles = () => {
    switch (size) {
      case 'normal':
        return {
          fontSize: 'calc(16px + 0.55vw)',
        };
      case 'small':
        return {
          fontSize: 'calc(14px + 0.425vw)',
        };
      default:
        return {};
    }
  }

  const styles = {
    buttonWrapper: {
      cursor: 'pointer',
      boxSizing: 'border-box',
      borderRadius: '100px',
      ...getTypeSpecificStyles(),
      ...getSizeSpecificStyles(),
      ...style
    },
    processingWrapper: {
      boxSizing: 'border-box',
      borderRadius: '100px',
      ...getTypeSpecificStyles(),
      ...getSizeSpecificStyles(),
      ...style
    },
  };

  const Component = href ? 'a' : 'button';

  if (type !== 'wrapper') {
    return (
      <Component
        tabIndex={0}
        type={href ? 'link' : 'button'}
        onClick={(input) => onPress(input)}
        className={cssModules[type]}
        style={styles.buttonWrapper}
        href={href}
        target={openInNewTab ? '_blank' : undefined}
        rel="noreferrer"
      >
        {value}
      </Component>
    );
  } else {
    return (
      <Component
        tabIndex={0}
        type={href ? 'link' : 'button'}
        className={cssModules[type]}
        style={styles.buttonWrapper}
        onClick={(input) => onPress(input)}
        href={href}
        target={openInNewTab ? '_blank' : undefined}
        rel="noreferrer"
      >
        {children}
      </Component>
    );
  }
}

Button.propTypes = {
   children: PropTypes.any,
   value: PropTypes.any,
   type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text', 'wrapper']),
   onClick: (props, propName, componentName) => {
      if (!props.onClick && !props.href) {
         return new Error(`One of props 'onClick' or 'href' was not specified in '${componentName}'.`);
      }
   },
   href: (props, propName, componentName) => {
      if (!props.onClick && !props.href) {
         return new Error(`One of props 'onClick' or 'href' was not specified in '${componentName}'.`);
      }
   }
};

export default Button;
