import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSpring, animated, } from 'react-spring';
import { ThemeContext } from '../../constants/themes';
import { store } from '../../utils/store';
import { Analytics, getAPYStringFromAPINumber, parseIfPossible, useLocationChange, } from '../../utils';
import Loader from '../../components/atoms/Loader';
import Card from '../../components/atoms/Card';
import Type from '../../components/atoms/Type';
import Button from '../../components/atoms/Button';
import NavBar from '../../components/molecules/NavBar';
import PointsProgressBar from '../../components/molecules/PointsProgressBar';
import AnimatedDots from './components/AnimatedDots';
import DroppingSoon from './components/DroppingSoon';
import InstagramGiveawayDetails from './components/InstagramGiveawayDetails';
import TwitterSlotDetails from './components/TwitterSlotDetails';
import MissionDetails from './components/MissionDetails';
import RewardsListModal from './components/RewardsListModal';
import HowItWorksModal from './components/HowItWorksModal';
import DateCountdown from './components/DateCountdown';
import { ReactComponent as RepeatIcon } from '../../assets/img/repeat.svg';
import { ReactComponent as DangerIcon } from '../../assets/img/danger.svg';
import { ReactComponent as InformationIcon } from '../../assets/img/information.svg';
import { ReactComponent as CompleteIcon } from '../../assets/img/complete.svg';
import { ReactComponent as PointIcon } from '../../assets/img/point.svg';
import { ReactComponent as CaretRight } from '../../assets/img/caret-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/img/arrow-left.svg';
import Mission from './mission';
import Auth from '../../api/auth';
import Crescent from '../../api/crescent';
import style from './index.module.css';
import PostCompleteMissionNoticeModal from './components/PostCompleteMissionNoticeModal';

const auth = new Auth();
const crescent = new Crescent();

const ACTIVE_TRANSITION_DELAY = 350;

const Dashboard = (props) => {
  const theme = useContext(ThemeContext);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const [isLoading, setIsLoading] = useState(true);
  const [viewingMission, setViewingMission] = useState(null);
  const [activeMission, setActiveMission] = useState(null);

  // background gradient tilt shift
  const [tiltShiftDirection, setTiltShiftDirection] = useState('right');
  const [tiltShift, tiltSpringApi] = useSpring(() => ({ to: { background: 'linear-gradient(160deg, #6B01D5 -40%, #0a0014 70%)', } }));

  const toggleBackgroundTiltShift = () => {
    if (tiltShiftDirection === 'left') {
      setTiltShiftDirection('right');
      tiltSpringApi.start({ config: { velocity: 0, friction: 100, }, to: { background: 'linear-gradient(200deg, #6B01D5 -40%, #0a0014 70%)', } });
    } else {
      setTiltShiftDirection('left');
      tiltSpringApi.start({ config: { velocity: 0, friction: 100, }, to: { background: 'linear-gradient(160deg, #6B01D5 -40%, #0a0014 70%)', } });
    }
  }

  const [fadeAmount, fadeSpringApi] = useSpring(() => ({ opacity: 1, }))

  const pulseFadeContent = (from) => {
    if (from !== 0) {
      fadeSpringApi.start({ opacity: 0, });
      setTimeout(() => {
        pulseFadeContent(0);
      }, ACTIVE_TRANSITION_DELAY);
    } else {
      fadeSpringApi.start({ opacity: 1, });
    }
  }

  useEffect(() => {
    crescent.getUser().then(res => {
      if (res && res.data) {
        // Must be accepting tcpa
        if (res.data.tcpa_granted === false) {
          auth.signOut();
          props.history.push('/');
        } else if (res.data.info_complete === false) {
          auth.signOut();
          props.history.push('/');
        } else {
          dispatch({ type: 'SET_DATA', payload: res.data });
          setIsLoading(false);
        }
      } else {
        auth.signOut();
        props.history.push('/');
      }
    });
  }, []);

  const [showTrackMyRewards, setShowTrackMyRewards] = useState(false);
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const maxRewardApy = useMemo(() => Math.max.apply(Math, state.apy_reward_ladder?.map((r) => r.apy_reward)), [state.apy_reward_ladder]);

  const [isReloadingData, setIsReloadingData] = useState(false);

  const reloadData = () => {
    setIsReloadingData(true);
    crescent.getUser().then(res => {
      if (res && res.data) {
        dispatch({ type: 'SET_DATA', payload: res.data });
      }
      setIsReloadingData(false);
    });
  }

  const signOut = async () => {
    await auth.signOut();
    props.history.push('/');
  }

  const toggleStartMission = (mission) => {
    pulseFadeContent();
    setTimeout(() => {
      if (!mission) {
        setActiveMission(null);
        setViewingMission(activeMission);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      } else {
        setActiveMission(mission);
        setViewingMission(null);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    }, ACTIVE_TRANSITION_DELAY);
  }

  const [justCompletedMission, setJustCompletedMission] = useState(false);
  // object { header, description, note }
  const [postCompleteMissionNotice, setPostCompleteMissionNotice] = useState(null);

  useEffect(() => {
    let timeout;
    if (justCompletedMission) {
      timeout = setTimeout(() => {
        setJustCompletedMission(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [justCompletedMission]);

  const onMissionComplete = (missionId, missionType, missionData) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setIsLoading(true);
    console.log('post mission complete data', missionData);
    if (missionData && missionData.error) {
      setPostCompleteMissionNotice({
        header: 'Sorry, there was an error',
        description: "We'll do our best to fix this issue ASAP.",
        note: missionData.error,
      });
    }
    crescent.getUser().then(res => {
      if (res && res.data) {
        if (missionType === 'standard_referral') {
          setPostCompleteMissionNotice({
            header: 'Thanks for spreading the ❤️',
            description: "You'll earn points as soon as someone joins the waitlist using your unique referral link.",
            note: null,
          });
          Analytics.trackMissionCompleted({ taskId: missionId });
        } else if (missionType === 'instagram_giveaway' && res.data.async_task_status && res.data.async_task_status[missionId] && res.data.async_task_status[missionId].status === 'pending') {
          setPostCompleteMissionNotice({
            header: "You've entered to win 🎟",
            description: "Keep an eye on our social media to see when we've announced a winner.",
            note: null,
          });
          Analytics.trackMissionCompleted({ taskId: missionId });
        } else if (res.data.async_task_status && res.data.async_task_status[missionId] && res.data.async_task_status[missionId].status === 'pending') {
          setPostCompleteMissionNotice({
            header: "Mission under review 👊",
            description: "Once we verify you've completed the mission, you'll receive your reward.",
            note: null,
          });
          Analytics.trackMissionCompleted({ taskId: missionId });
        } else if (res.data.async_task_status && res.data.async_task_status[missionId] && res.data.async_task_status[missionId].status === 'failed') {
          setPostCompleteMissionNotice({
            header: "Sorry, mission failed",
            description: "Looks like you didn't meet the mission requirements. You'll get 'em next time.",
            note: res.data.async_task_status[missionId].readable_error,
          });
          Analytics.trackMissionFailed({ taskId: missionId });
        } else {
          setJustCompletedMission(true);
          toggleBackgroundTiltShift();
          Analytics.trackMissionCompleted({ taskId: missionId });
        }
        dispatch({ type: 'SET_DATA', payload: res.data });
      }
      setActiveMission(null);
      setViewingMission(null);
      setIsLoading(false);
    });
  }

  const toggleViewMissionDetails = (mission, asyncStatus) => {
    pulseFadeContent();
    toggleBackgroundTiltShift();
    setTimeout(() => {
      if (!mission) {
        setActiveMission(null);
        setViewingMission(null);
      } else {
        setActiveMission(null);
        setViewingMission(mission);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        Analytics.trackMissionViewed({
          taskId: mission.task_id,
          timesCompleted: mission.times_completed,
        });
      }
    }, ACTIVE_TRANSITION_DELAY);
  }

  const [viewingDroppingSoonMission, setViewingDroppingSoonMission] = useState(false);

  const toggleViewDroppingSoon = (shouldView = false) => {
    pulseFadeContent();
    toggleBackgroundTiltShift();
    setTimeout(() => {
      if (!shouldView) {
        setViewingDroppingSoonMission(false);
      } else {
        setViewingDroppingSoonMission(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        Analytics.trackMissionViewed({
          taskId: 'dropping_soon',
          timesCompleted: null,
        });
      }
    }, ACTIVE_TRANSITION_DELAY);
  }

  const renderMissionDetails = (taskType) => {
    switch(taskType) {
      case 'dropping_soon':
        return (
          <DroppingSoon
            goBack={() => toggleViewMissionDetails(null)}
          />
        );
      case 'instagram_giveaway':
        return (
          <InstagramGiveawayDetails
            mission={viewingMission}
            goBack={() => toggleViewMissionDetails(null)}
            startMission={() => toggleStartMission(viewingMission)}
            reloadData={reloadData}
            isReloadingData={isReloadingData}
          />
        );
      case 'twitter_slot':
        return (
          <TwitterSlotDetails
            mission={viewingMission}
            goBack={() => toggleViewMissionDetails(null)}
            startMission={() => toggleStartMission(viewingMission)}
            reloadData={reloadData}
            isReloadingData={isReloadingData}
          />
        );
      default:
        return (
          <MissionDetails
            mission={viewingMission}
            goBack={() => toggleViewMissionDetails(null)}
            startMission={() => toggleStartMission(viewingMission)}
            reloadData={reloadData}
            isReloadingData={isReloadingData}
          />
        );
    }
  }

  const renderMissionStatusNotice = (status, taskType) => {
    switch (status) {
      case 'repeat':
        if (taskType === 'twitter_slot') {
          return (
            <div className={style.missionStatusNoticeWrapper}>
              <RepeatIcon width={18} height={18} />
              <Type type="caption-sm" style={{ color: theme.secondary, margin: 0, marginLeft: '0.5em', textTransform: 'uppercase', fontWeight: '600', }}>Spin for More Points</Type>
            </div>
          );
        } else {
          return (
            <div className={style.missionStatusNoticeWrapper}>
              <RepeatIcon width={18} height={18} />
              <Type type="caption-sm" style={{ color: theme.secondary, margin: 0, marginLeft: '0.5em', textTransform: 'uppercase', fontWeight: '600', }}>Repeat for More Points</Type>
            </div>
          );
        }
      case 'pending':
        if (taskType === 'instagram_giveaway') {
          return (
            <div className={style.missionStatusNoticeWrapper}>
              <InformationIcon fill={theme.warning} width={18} height={18} />
              <Type type="caption-sm" style={{ color: theme.warning, margin: 0, marginLeft: '0.5em', textTransform: 'uppercase', fontWeight: '600', }}>You're Entered</Type>
            </div>
          );
        }
        return (
          <div className={style.missionStatusNoticeWrapper}>
            <InformationIcon fill={theme.warning} width={18} height={18} />
            <Type type="caption-sm" style={{ color: theme.warning, margin: 0, marginLeft: '0.5em', textTransform: 'uppercase', fontWeight: '600', }}>Pending</Type>
          </div>
        );
      case 'failed':
        return (
          <div className={style.missionStatusNoticeWrapper}>
            <DangerIcon fill={theme.danger} width={18} height={18} />
            <Type type="caption-sm" style={{ color: theme.danger, margin: 0, marginLeft: '0.5em', textTransform: 'uppercase', fontWeight: '600', }}>Failed</Type>
          </div>
        );
      case 'complete':
        return (
          <div className={style.missionStatusNoticeWrapper}>
            <CompleteIcon fill={theme.success} width={18} height={18} />
            <Type type="caption-sm" style={{ color: theme.success, margin: 0, marginLeft: '0.5em', textTransform: 'uppercase', fontWeight: '600', }}>Complete</Type>
          </div>
        );
      default:
        return null;
    }
  }

  const renderMissionCard = (mission) => {
    const missionAsyncStatus = parseIfPossible(state.async_task_status)[mission.task_id];

    return (
      <Card
        key={JSON.stringify(mission)}
        type="inset"
        style={{ width: '100%', marginBottom: '8px', cursor: 'pointer', }}
        onClick={() => toggleViewMissionDetails(mission, missionAsyncStatus)}
      >
        <div className={style.missionRow}>
          <div className={style.missionInfo}>
            <div className={style.missionEmoji}>
              <span>{mission.emoji}</span>
            </div>
            <div>
              <div className={style.missionInfo}>
                <Type style={{ margin: 0, marginBottom: '6px', }}><strong>{mission.name}</strong></Type>
              </div>
              {missionAsyncStatus
                // mission is either pending review or failed
                ? renderMissionStatusNotice(missionAsyncStatus.status, mission.task_type) : (
                  (mission.times_completed > 0 && mission.completion_limit === -1
                    // mission can be repeated again
                    ? renderMissionStatusNotice('repeat', mission.task_type) : (
                      (mission.times_completed > 0 && mission.times_completed >= mission.completion_limit
                        // mission has been completed
                        ? renderMissionStatusNotice('complete', mission.task_type) : (
                          // mission has never been completed
                          <Type type="caption" style={{ margin: 0, }}>{mission.short_description}</Type>
                        )))))}
            </div>
          </div>
          <div className={style.missionPoints}>
            {mission.point_reward
              ? (
                <Type style={{ marginTop: '-8px', marginBottom: '8px', fontWeight: '600', whiteSpace: 'nowrap', }}><PointIcon width={22} height={22} className={style.point} /> {mission.point_reward}</Type>
              ) : ((mission.cash_reward
                ? (
                  <Type style={{ marginTop: '-8px', marginBottom: '8px', fontWeight: '600', whiteSpace: 'nowrap', }}><PointIcon width={22} height={22} className={style.point} style={{ visibility: 'hidden', }} /> ${mission.cash_reward}</Type>
                ) : (
                  <Type style={{ marginTop: '-8px', marginBottom: '8px', fontWeight: '600', whiteSpace: 'nowrap', }}><PointIcon width={22} height={22} className={style.point} /> ???</Type>
                ))
              )}
            <div style={{ marginRight: '6px', marginTop: '2px', }}><CaretRight width={16} height={16} /></div>
          </div>
        </div>
      </Card>
    );
  }

  useLocationChange(() => {
    Analytics.page('Missions');
  });

  // Missions won't show up if not in this list
  const supportedMissionTypes = useMemo(() => ['instagram_giveaway', 'standard_referral', 'survey_complete', 'tweet_retweet', 'twitter_name_emoji', 'twitter_slot'], []);

  return (
    <>
      <div className={`${style.loaderBackground} ${!isLoading ? style.fadeOut : ''}`}>
        <Loader />
      </div>
      <PostCompleteMissionNoticeModal show={postCompleteMissionNotice !== null} close={() => setPostCompleteMissionNotice(null)} notice={postCompleteMissionNotice} />
      <RewardsListModal show={showTrackMyRewards} close={() => setShowTrackMyRewards(false)} />
      <HowItWorksModal show={showHowItWorks} close={() => setShowHowItWorks(false)} />
      <animated.div className={style.background} style={{ backgroundColor: theme.body, ...tiltShift, }}>
        <NavBar history={props.history} showAccount={!activeMission} />
        <animated.div className={style.content} style={fadeAmount}>
          {!viewingMission && !viewingDroppingSoonMission ? (
            (!activeMission ? (
              <>
                <div className={style.header}>
                  <Type type="h1" component="h1" style={{ fontWeight: '600', }}>Crescent Waitlist</Type>
                  {/* <Type type="p" component="p">Your stats:</Type> */}
                  <Card type="primary" style={{ width: '100%', margin: 0, padding: '0 8px', }}>
                    <div className={style.statusRow}>
                      <div>
                        <Type type="h3" style={{ marginBottom: 0, }}>{getAPYStringFromAPINumber(state.apy || 300)} APY</Type>
                        <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '6px', }}>For {state.duration} month{state.duration !== 1 ? 's' : ''}*</Type>
                      </div>
                      <div className={style.vc} style={{ backgroundColor: theme.text, }} />
                      <div>
                        <Type type="h3" style={{ marginBottom: 0, }}>#{state.rank}</Type>
                        <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '6px', }}>{state.waitlist_size} in line</Type>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className={style.points}>
                  <div className={style.pointsHeader}>
                    <Type type="h2" component="h2" style={{ margin: 0, }}>Points</Type>
                  </div>
                  <PointsProgressBar
                    rank={state.rank}
                    duration={state.duration}
                    points={state.points}
                    durationRewardLadder={state.duration_reward_ladder}
                    rankProgression={state.rank_progression}
                    justCompletedMission={justCompletedMission}
                  />
                  <div className={style.pointsActionsWrapper}>
                    <Button value="Rewards List" onClick={setShowTrackMyRewards} type="primary" size="small" style={{ marginRight: '0.5em', }} />
                    <Button value="How it Works" onClick={setShowHowItWorks} type="secondary" size="small" />
                  </div>
                </div>
                <div className={style.missions} style={{ backgroundColor: theme.body, }}>
                  <div className={style.missionsContent}>
                    <Type type="h2" component="h2" style={{ marginBottom: 0, }}>Missions</Type>
                    <Type style={{ marginTop: '4px', color: theme.subtle }}>Complete missions to earn points</Type>
                    {/* More coming soon card */}
                    <Card
                      type="inset"
                      style={{ width: '100%', marginBottom: '8px', marginTop: '8px', cursor: 'pointer', }}
                      onClick={() => toggleViewDroppingSoon(true)}
                    >
                      <div className={style.missionRow}>
                        <div className={style.missionInfo}>
                          <div className={style.missionEmoji}>
                            <span>🤖</span>
                          </div>
                          <div>
                            <div className={style.missionInfo}>
                              <Type style={{ margin: 0, marginBottom: '6px', }}><strong>New Mission Dropping Monday</strong></Type>
                              {/* <AnimatedDots /> */}
                            </div>
                            <Type type="caption" style={{ margin: 0, }}>
                              <DateCountdown
                                dateTo={new Date(('February 28, 2022 12:00:00 CST')).toString()}
                                locales={['y', 'm', 'd', 'hr', 'm', 's']}
                                locales_plural={['y', 'm', 'd', 'h', 'm', 's']}
                                callback={() => console.log('date expired!')}
                              />
                            </Type>
                          </div>
                        </div>
                        <div className={style.missionPoints}>
                          <Type style={{ marginTop: '-8px', marginBottom: '8px', fontWeight: '600', whiteSpace: 'nowrap', }}><PointIcon width={22} height={22} className={style.point} /> ???</Type>
                          <div style={{ marginRight: '6px', marginTop: '2px', }}><CaretRight width={16} height={16} /></div>
                        </div>
                      </div>
                    </Card>
                    <hr />
                    {state.tasks?.map(task => supportedMissionTypes.includes(task.task_type) ? renderMissionCard(task) : null)}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={style.missionDetailHeader}>
                  <br />
                  <br />
                  <Button type="wrapper" onClick={() => toggleStartMission(null)}>
                    <div className={style.backButton}>
                      <ArrowLeft width={16} height={16} />
                      <Type style={{ marginLeft: '0.5em', fontWeight: 600, }}>Back to Mission Details</Type>
                    </div>
                  </Button>
                </div>
                <div className={style.missionSteps}>
                  <Mission mission={activeMission} onMissionComplete={onMissionComplete} />
                </div>
              </>
            ))
          ) : (viewingDroppingSoonMission
            ? (
              <DroppingSoon
                goBack={() => toggleViewDroppingSoon(false)}
              />
            ) : renderMissionDetails(viewingMission.task_type)
          )}
          <footer>
            <Type type="caption"><strong>© Crescent Financial, Inc.</strong></Type>
          </footer>
        </animated.div>
      </animated.div>
    </>
  );
}

export default Dashboard;
