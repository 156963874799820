import React, { useCallback, useContext, useState, } from 'react';
import { default as FacebookPixel } from 'react-facebook-pixel';
import { default as SnapchatPixel } from 'react-snapchat-pixel';
import { ThemeContext } from '../../../../constants/themes';
import Type from '../../../../components/atoms/Type';
import Button from '../../../../components/atoms/Button';
import TextInput from '../../../../components/atoms/TextInput';
import Loader from '../../../../components/atoms/Loader';
import { validateEmail, Analytics } from '../../../../utils';
import Crescent from '../../../../api/crescent';
import style from './index.module.css';

const crescent = new Crescent();

const InfoCollect = (props) => {
  const theme = useContext(ThemeContext);

  const [formInputs, setFormInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [validation, setValidation] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const validate = () => {
    let validation = [];
    if (!formInputs.firstName || !formInputs.lastName) {
      validation.push("Please enter your first and last name.");
    } else if (!formInputs.email || !validateEmail(formInputs.email)) {
      validation.push("Please enter a valid email address.");
    }
    return validation;
  }

  const trackNewCompletedRegistration = () => {
    FacebookPixel.track('CompleteRegistration', null);
    SnapchatPixel.track('SUBSCRIBE');

    // Reddit Pixel
    if (window.rdt) {
      window.rdt('track', 'SignUp')
    }
  }

  const submit = async () => {
    setValidation(null);
    setIsProcessing(true);
    const validation = validate();
    if (validation.length === 0) {
      crescent.postUserInfo(formInputs.firstName, formInputs.lastName, formInputs.email).then(res => {
        if (!!res && !res.error) {
          props.nextStep();
          Analytics.identify(res.phone_number, {
            firstName: formInputs.firstName,
            lastName: formInputs.lastName,
            email: formInputs.email,
            createdAt: res.datetime_created,
          });
          Analytics.trackAccountCreated({phone: res.phone_number, email: formInputs.email,});
          trackNewCompletedRegistration();
        } else {
          setValidation(['Sorry, there was an error. Please try again.']);
          setIsProcessing(false);
        }
      });
    } else {
      setValidation(validation);
      setIsProcessing(false);
    }
  }

  const onFormKeyDown = (e) => {
    if (e.key === 'Enter') {
      submit();
    }
  }

  const onInputChange = useCallback((e) => {
    setFormInputs(prevFormInputs => ({
      ...prevFormInputs,
      [e.target.name]: e.target.value,
    }));
  }, []);

  return (
    <div className={style.bottom}>
      <div className={style.form} onKeyDown={onFormKeyDown}>
        <Type style={{ color: theme.warning, }}>{validation?.toString()}</Type>
        <TextInput
          label="First Name"
          name="firstName"
          placeholder="John"
          width={'100%'}
          value={formInputs.firstName}
          onChange={onInputChange}
          style={{ marginBottom: '0.5em', }} 
        />
        <TextInput
          label="Last Name"
          name="lastName"
          placeholder="Doe"
          width={'100%'}
          value={formInputs.lastName}
          onChange={onInputChange}
          style={{ marginBottom: '0.5em', }} 
        />
        <TextInput
          label="Email Address"
          name="email"
          placeholder="your@email.com"
          width={'100%'}
          value={formInputs.email}
          onChange={onInputChange}
        />
        {isProcessing && (
          <>
            <br />
            <Loader />
          </>
        )}
      </div>
      <div className={style.actionFooter} style={{ backgroundColor: theme.body, }}>
        <div style={{ margin: 'auto', maxWidth: '20em', }}>
          <Button
            value="Submit"
            onClick={() => submit()}
            type="primary"
            disabled={!formInputs.firstName || !formInputs.lastName || !formInputs.email}
            isProcessing={isProcessing}
            style={{ width: '100%', marginTop: '1em' }}
          />
        </div>
      </div>
    </div>
  );
}

export default InfoCollect;
