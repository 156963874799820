import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { default as FacebookPixel } from 'react-facebook-pixel';
import { default as SnapchatPixel } from 'react-snapchat-pixel';
import { ThemeContext } from '../../../../constants/themes';
import Type from '../../../../components/atoms/Type';
import Button from '../../../../components/atoms/Button';
import TextInput from '../../../../components/atoms/TextInput';
import Loader from '../../../../components/atoms/Loader';
import { ReactComponent as Checkmark } from '../../../../assets/img/checkmark.svg';
import Auth from '../../../../api/auth';
import Crescent from '../../../../api/crescent';
import style from './index.module.css';
import { Analytics } from '../../../../utils';

const auth = new Auth();
const crescent = new Crescent();
  
const VerificationCheck = (props) => {
  const theme = useContext(ThemeContext);

  const [formInputs, setFormInputs] = useState({
    code: '',
    termAgreement: false,
  });
  const [validation, setValidation] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const validate = () => {
    let validation = [];
    if (!formInputs.code) {
      validation.push("Please enter a verification code.");
    } else if (formInputs.code.length !== 6) {
      validation.push("Please enter a valid verification code.");
    } else if (!props.info.tcpaGranted && !formInputs.termAgreement) {
      validation.push('Please review the terms and select "I agree" to proceed to the waitlist.');
    }
    if (!props.info?.phoneNumber) {
      validation.push("Error texting phone number.");
    }
    return validation;
  }

  const trackNewLead = (referralId) => {
    FacebookPixel.track('Lead', { wasReferred: !!referralId, });
    SnapchatPixel.track('SIGN_UP', { wasReferred: !!referralId, });
    // TikTok Pixel
    if (window.ttq) {
      window.ttq.track('SubmitForm');
    }
    // Reddit Pixel
    if (window.rdt) {
      window.rdt('track', 'Lead')
    }
    // Google Ads Conversion
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: `${process.env.REACT_APP_GOOGLE_ADS_ID}/FkvvCJSOs5EDELPs25AB`,
        event_callback: () => {
          console.log('GA ready'); 
        },
      });
    }
  }

  const submit = async () => {
    setValidation(null);
    setIsProcessing(true);
    const validation = validate();
    if (validation.length === 0) {
      // TODO: Does the API ignore the referral code if info.isExistingUser?
      crescent.verificationCheck(props.info?.phoneNumber, formInputs.code, props.info?.referralId).then(async res => {
        if (!!res && !!res.auth_token) {
          await auth.storeAuthInfo(res.auth_token, props.info?.phoneNumber);
          props.setInfo({
            ...props.info,
            referralSuccessful: res.referred || false,
            infoCollected: res.info_complete || false,
          });
          Analytics.identify(res.phone_number);
          Analytics.trackVerificationCompleted({phone: res.phoneNumber});
          if (!res.existing_user) {
            Analytics.trackSignedUp();
            trackNewLead(props.info?.referralId);
          }
          else {
            Analytics.trackSignedIn();
          }
          // Skip extra step if already collected info
          if (res.info_complete === true) {
            props.nextStep(2);
          } else {
            props.nextStep();
          }
        } else {
          setValidation(['Invalid code. Please try again.']);
          setIsProcessing(false);
          Analytics.trackVerificationFailed({phone: res.phoneNumber});
        }
      });
    } else {
      setValidation(validation);
      setIsProcessing(false);
    }
  }

  const onInputChange = useCallback((e) => {
    setFormInputs(prevFormInputs => ({
      ...prevFormInputs,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const setTermAgreement = (agreement) => {
    setFormInputs(prevFormInputs => ({
      ...prevFormInputs,
      termAgreement: agreement,
    }));
  }

  return (
    <div className={style.bottom}>
      <div className={style.form}>
        {validation
          ? (
            <Type style={{ color: theme.warning, }}>{validation?.toString()}</Type>
          ) : (!isProcessing
            ? (
              <Type type="caption">We texted you a code to verify your phone number.</Type>
            ) : (
              <>
                <br />
                <br />
              </>
            ))}
        <TextInput
          shouldFocusOnMount
          label="Verification Code"
          name="code"
          placeholder="XXXXXX"
          format="number"
          max={6}
          width={'100%'}
          value={formInputs.code}
          onChange={onInputChange}
        />
        {!props.info.tcpaGranted && (
          <>
            <br />
            <div className={style.terms}>
              <Type type="caption" maxHeight="10em">By clicking the “I Agree” button below, you acknowledge receipt of and agree to be bound to the terms below.  You also acknowledge receipt of the <a target="_blank" href="https://drive.google.com/file/d/1JcYf0A4g1-8MLR9XhQngDpDNnxijzEhs/view?usp=sharing">Privacy Policy</a> and acknowledge and agree to the <a target="_blank" href="https://drive.google.com/file/d/1vsuMYCoFFr9l-MWfDqY5dJga_si1D4OK/view?usp=sharing">E-Sign Disclosure</a>.<br/><br/>By your indication via the “I Agree” button below, you agree that Crescent Financial, Inc. or any party authorized to act on behalf of Crescent Financial, Inc. including, but not limited to, Twilio (all hereinafter “Crescent” or “us”), may call or send SMS text messages for telemarketing or advertising purposes to you at the telephone number listed above, which may be updated from time to time, using an automatic telephone dialing system or an artificial or prerecorded voice.  If you provide to us a wireless telephone number, you represent that you are the wireless subscriber or customary user and have the authority to give this consent.  Further, you agree to notify us any time a landline or wireless telephone number you have provided to us changes.<br/><br/>By indicating your agreement via the “I Agree” button below, you acknowledge that you are not required to sign or agree to enter into this agreement as a condition of purchasing any property, goods, or services.  However, in order to join the Crescent Save Waitlist, you must enter into this agreement, which does not obligate you to purchase any property, goods, or services, and does not obligate you to create an account.  You may opt-out of the telemarketing/advertising communications by emailing us at support@crescent.app; this is the exclusive opt-out mechanism.  Crescent will act on your opt-out request within a reasonable period of time.</Type>
              <div className={style.termsShadow} />
            </div>
            <Button type="wrapper" onClick={() => setTermAgreement(!formInputs.termAgreement)}>
              <div className={style.checkWrapper}>
                {formInputs.termAgreement === true ? (
                  <div className={style.checked} style={{ backgroundColor: theme.success, }}><Checkmark width={18} height={18} /></div>
                ) : (
                  <div className={style.unchecked}/>
                )}
                <Type>I agree to the terms above</Type>
              </div>
            </Button>
          </>
        )}
        {isProcessing && (
          <>
            <br />
            <Loader />
          </>
        )}
      </div>
      <div className={style.actionFooter} style={{ backgroundColor: theme.body, }}>
        <div style={{ margin: 'auto', maxWidth: '20em', }}>
          <Button
            value="Continue"
            onClick={() => submit()}
            type="primary"
            disabled={!formInputs.code || (!props.info.tcpaGranted && !formInputs.termAgreement)}
            isProcessing={isProcessing}
            style={{ width: '100%', }}
          /> 
        </div>
      </div>
    </div>
  );
}
  
export default VerificationCheck;
  