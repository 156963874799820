import React, { useEffect, useState, } from 'react';
import Button from '../../components/atoms/Button';
import TextInput from '../../components/atoms/TextInput';
import Auth from '../../api/auth';
import Crescent from '../../api/crescent';

const auth = new Auth();
const crescent = new Crescent();

const NukeAccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!process.env.REACT_APP_IS_PROD || process.env.REACT_APP_IS_PROD === 'true') {
      window.location.href = '/';
    } else {
      setIsLoading(false);
    }
  }, [ isLoading, ]);

  const [phoneNumber, setPhoneNumber] = useState('');

  const onPhoneNumberInput = (e) => {
    setPhoneNumber(e.target.value);
  }

  const submit = () => {
    const sanitizePhoneNumber = (pn) => {
      return pn.replace(/\D/g, '').trim();
    };
    const pn = `+1${sanitizePhoneNumber(phoneNumber)}`;

    crescent.deleteUser(pn).then(res => {
      console.log(res);
      auth.signOut().then(() => {
        window.location.href = '/';
      });
    });
  }

  if (isLoading) return null;
  return (
    <div>
      <h1>Nuke Account</h1>
      <TextInput
        label="Phone Number"
        name="phoneNumber"
        placeholder="(123) 456-7890"
        format="phoneNumber"
        width={'100%'}
        prepend={'+1'}
        value={phoneNumber}
        onChange={onPhoneNumberInput}
      />
      <br />
      <Button value="Delete User" onClick={() => submit()} disabled={!phoneNumber} />
    </div>
  );
}

export default NukeAccount;