import React, { useContext, useEffect, useState } from 'react';
import { useSpring, animated, } from 'react-spring';
import queryString from 'query-string';
import { ThemeContext } from '../../constants/themes';
import Loader from '../../components/atoms/Loader';
import Type from '../../components/atoms/Type';
import Card from '../../components/atoms/Card';
import NavBar from '../../components/molecules/NavBar';
import AnimatedNumberType from '../../components/molecules/AnimatedNumberType';
import { ReactComponent as Rocket } from '../../assets/img/rocket.svg';
import VerificationCreate from './components/VerificationCreate';
import VerificationCheck from './components/VerificationCheck';
import TermsCheck from './components/InfoCollect';
import PostJoin from './components/PostJoin';
import Auth from '../../api/auth';
import Crescent from '../../api/crescent';
import style from './index.module.css';
import { Analytics, useLocationChange } from '../../utils/index';

const auth = new Auth();
const crescent = new Crescent();

// 30 seconds
const TIME_SINCE_LAST_WAITLIST_SIZE_FETCH_BUFFER = 1000 * 30;

const steps = [
  {
    number: 0,
    description: 'Send code to phone number',
  },
  {
    number: 1,
    description: 'Verify code and accept terms',
  },
  {
    number: 2,
    description: 'Info collection',
  },
  {
    number: 3,
    description: 'Join/login confirmation',
  },
];

const Join = (props) => {
  const theme = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(true);

  const [currentWaitlistSize, setCurrentWaitlistSize] = useState(0);
  const [timeAtLastWaitlistSizeFetch, setTimeAtLastWaitlistSizeFetch] = useState(null);

  useEffect(() => {
    if (timeAtLastWaitlistSizeFetch === null || ((Date.now() - timeAtLastWaitlistSizeFetch) > TIME_SINCE_LAST_WAITLIST_SIZE_FETCH_BUFFER)) {
      crescent.getWaitlistSize().then(res => {
        if (!!res && !!res.waitlist_size) {
          setTimeAtLastWaitlistSizeFetch(Date.now());
          setCurrentWaitlistSize(res.waitlist_size);
        }
      });
    }
  }, []);

  const [step, setStep] = useState(steps[0]);
  const backStep = () => setStep(steps[Math.max(step.number - 1, 0)]);
  const nextStep = (numToSkip = 1) => {
    // if finished
    if (step.number + numToSkip > steps.length - 1) {
      // TODO: Throw an error?
    } else {
      setStep(steps[step.number + numToSkip]);
    }
  }

  const [info, setInfo] = useState({
    phoneNumber: null,
    // Phone number coming from external site, meaning
    // we can accept it and immediately try to create
    // the verification.
    externalPhoneNumber: null,
    verificationCodeParam: null,
    referralId: null,
    referralSuccessful: null,
    isExistingUser: false,
    tcpaGranted: false,
    infoCollected: false,
  });

  // rocket animation
  const [liftoff, springApi] = useSpring(() => ({ to: { top: 0, left: '20%', }}));

  const queryParams = queryString.parse(window.location.search);
  const referralId = queryParams?.ref || null;
  // This come from externally sourced verification requests
  const externalPhoneNumber = queryParams?.phone_entered || null;

  useEffect(() => {
    if (auth.isAuthenticated()) {
      props.history.push('/missions');
    } else if (externalPhoneNumber) {
      setInfo({
        ...info,
        externalPhoneNumber,
        referralId,
      });
    } else if (referralId) {
      setInfo({
        ...info,
        referralId,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [ referralId, externalPhoneNumber, ]);

  useEffect(() => {
    if (step.number === 3) {
      setTimeout(() => {
        springApi.start({ config: { velocity: 0, friction: 100, }, to: { top: -400, left: '60%', }});
      }, 200);
    }
    return () => {
      springApi.stop();
    }
  }, [ step.number, ]);

  const renderSteps = () => (
    <>
      {step.number === 0 && <VerificationCreate nextStep={nextStep} info={info} setInfo={setInfo} stopLoading={() => setIsLoading(false)}  />}
      {step.number === 1 && <VerificationCheck backStep={backStep} nextStep={nextStep} info={info} setInfo={setInfo} />}
      {step.number === 2 && <TermsCheck info={info} nextStep={nextStep} />}
      {step.number === 3 && <PostJoin info={info} goToMissions={() => props.history.push('/missions')} />}
    </>
  );

  const isPostJoin = step.number === 3;

  const headerText = !info.isExistingUser ? (isPostJoin ? 'Congrats 🎉' : (info.referralId ? "You've been referred 🤝" : 'Join the Waitlist')) : 'Welcome Back';
  const descriptionText = isPostJoin ? (info.referralSuccessful ? 'You can tell your friend that you were successfully referred 🌙' : '') : (!info.isExistingUser ? (info.referralId ? "Join your friend in line for Crescent's launch, complete missions, and earn points to unlock APY boosts." : "Get in line for Crescent's launch or sign back in. Complete missions. Earn points. Unlock APY boosts.") : 'Sign in to continue completing missions and earning points to unlock APY boosts.');

  useLocationChange((location) => {
    Analytics.page(location.pathname);
  });

  return (
    <>
      <div className={`${style.loaderBackground} ${!isLoading ? style.fadeOut : ''}`}>
        <Loader />
      </div>
      <div className={style.background}>
        <div className={style.headerWrapper} style={{ backgroundColor: theme.body }}>
          <NavBar history={props.history} showAccount={false} />
          <div className={style.header}>
            <div className={style.headerContent}>
              <br />
              <Type type="h1" component="h1" style={{ marginBottom: 0, }} alignCenter>{headerText}</Type>
              <Type component="h2" alignCenter>{descriptionText}</Type>
              {!info.isExistingUser && (
                <Card type="inset" style={{ margin: 0, padding: '0 6px', }}>
                  <div className={style.statusRow}>
                    <div>
                      <Type type="h3" style={{ marginBottom: 0, }}>3.0%</Type>
                      <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '6px', }}>Starting APY</Type>
                    </div>
                    <div className={style.vc} style={{ backgroundColor: theme.text, }} />
                    <div>
                      {currentWaitlistSize > 0
                        ? (
                          <AnimatedNumberType
                            type="h3"
                            style={{ marginBottom: 0, }}
                            value={currentWaitlistSize}
                            formatValue={(value) => value.toFixed(0)}
                            duration={500}
                            ease='easeInOut'
                          />
                        ) : (
                          <Type type="h3" style={{ marginBottom: 0, }}>—</Type>
                        )}
                      <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '6px', }}>Others in Line</Type>
                    </div>
                  </div>
                </Card>
              )}
            </div>
            <animated.div className={style.rocketWrapper} style={liftoff}>
              <Rocket />
            </animated.div>
          </div>
        </div>
        {renderSteps()}
      </div>
    </>
  );
}

export default Join;
