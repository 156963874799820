import React, { useContext, }  from 'react';
import { ThemeContext } from '../../../../constants/themes';
import { store } from '../../../../utils/store';
import Modal from '../../../../components/atoms/Modal';
import Card from '../../../../components/atoms/Card';
import Button from '../../../../components/atoms/Button';
import Type from '../../../../components/atoms/Type';
import { ReactComponent as ArrowLeft } from '../../../../assets/img/arrow-left.svg';
import { ReactComponent as Check } from '../../../../assets/img/outline-check.svg';
import { ReactComponent as Graph } from '../../../../assets/img/outline-graph.svg';
import { ReactComponent as Gift } from '../../../../assets/img/outline-gift.svg';
import style from './index.module.css';

const HowItWorksModal = ({ show, close, }) => {
  const theme = useContext(ThemeContext);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  return (
    <Modal show={show}>
      <div className={style.body}>
        <Button type="wrapper" onClick={close}>
          <div className={style.backButton}>
            <ArrowLeft width={18} height={18} style={{ marginTop: '4px', }} />
          </div>
        </Button>
        <Type type="h1" component="h3" style={{ marginTop: '0.5em', marginBottom: 0, }}>How it Works</Type>
        <Type>Here’s how to get the most out of our waitlist.</Type>
        <br />
        <Card type="inset">
          <Check width={32} height={32} style={{ marginTop: '4px', }} />
          <Type type="h3" style={{ marginTop: '0.4em', marginBottom: 0, }}>Complete missions</Type>
          <Type style={{ marginBottom: '0.5em', opacity: 0.8, }}>Select and complete missions. Check back regularly for new missions.</Type>
        </Card>
        <br />
        <Card type="inset">
          <Graph width={32} height={32} style={{ marginTop: '4px', }} />
          <Type type="h3" style={{ marginTop: '0.4em', marginBottom: 0, }}>Collect points</Type>
          <Type style={{ marginBottom: '0.5em', opacity: 0.8, }}>Hit point milestones to unlock a higher APY %, prize drops, or early access.</Type>
        </Card>
        <br />
        <Card type="inset">
          <Gift width={32} height={32} style={{ marginTop: '4px', }} />
          <Type type="h3" style={{ marginTop: '0.4em', marginBottom: 0, }}>Activate within the app</Type>
          <Type style={{ marginBottom: '0.5em', opacity: 0.8, }}>Waitlist rewards will be activated after signing up on the app.* Referral points will be confirmed after each referral signs up and makes their first deposit.</Type>
          <Type type="caption" style={{ marginBottom: '0.5em', opacity: 0.8, }}>* During the Crescent Beta, some rewards may not be available on the app. We will credit rewards as soon as possible and notify you when they become available.</Type>
        </Card>
      </div>
    </Modal>
  );
}

export default HowItWorksModal;