import React, { useContext } from 'react';
import { ThemeContext } from '../../../constants/themes';
import cssModules from './index.module.css';

const Card = ({children, onClick = undefined, type = 'primary', style}) => {
  const theme = useContext(ThemeContext);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  }

  const getTypeSpecificStyles = () => {
    switch (type) {
      case 'primary':
        return {
          backgroundColor: theme.verySubtle,
          backdropFilter: 'blur(30px)',
          boxShadow: '0 0 0 2px rgba(255, 255, 255, 0.2)',
        };
      case 'special':
        return {
          backgroundColor: theme.verySubtle,
          backdropFilter: 'blur(30px)',
          boxShadow: '0 0 0 2px rgba(170, 60, 275, 0.4)',
        };
      case 'inset':
        return {
          background: theme.verySubtle,
          backdropFilter: 'blur(30px)',
        };
      default:
        return {};
    }
  }

  const styles = {
    background: {
      width: 'auto',
      height: 'auto',
      boxSizing: 'border-box',
      padding: '1em',
      borderRadius: '16px',
      transition: 'all 0.1s ease',
      ...getTypeSpecificStyles(),
    },
  };

  return (
    <div
      tabIndex={onClick ? '0' : undefined}
      onKeyDown={onClick ? onKeyDown : undefined}
      style={{...styles.background, ...style}} onClick={onClick}
      className={onClick ? cssModules['clickable-card'] : null}
    >
      {children}
    </div>
  );
}

export default Card;
