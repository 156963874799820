import React from 'react';
import Modal from '../../../components/atoms/Modal';
import Card from '../../../components/atoms/Card';
import Button from '../../../components/atoms/Button';
import Type from '../../../components/atoms/Type';

const PostCompleteMissionNoticeModal = ({ show, close, notice, }) => {
  return (
    <Modal show={show}>
      <Card style={{ maxWidth: '40em', }}>
        <Type type="h3" component="h3">{notice?.header}</Type>
        <Type type="p" component="p">{notice?.description}</Type>
        {notice?.note && <Type type="p" component="p">{notice?.note}</Type>}
        <br />
        <Button
          value="Okay!"
          onClick={close}
          type="primary"
        />
        {/* TODO: Add Contact Support button */}
      </Card>
    </Modal>
  );
}

export default PostCompleteMissionNoticeModal;