import Auth from './auth';

export default class Crescent {
  constructor() {
    this.API_URL = process.env.REACT_APP_API_URL;
    this.auth = new Auth();
  }

  // Nuke user profile (must be dev environment)
  deleteUser = async (phoneNumber) => {
    if (!process.env.REACT_APP_IS_PROD || process.env.REACT_APP_IS_PROD === 'false') {
      return fetch(this.API_URL + '/delete_user', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          phone_number: phoneNumber.trim(),
        })
      }).then(response => {
        if (!response.ok) {
          console.log('response was not a 200-299 status code');
        }
        return response.json();
      }).catch(err => {
        console.log('Error', err);
      });
    }
  }

  getWaitlistSize = async () => {
    return fetch(this.API_URL + '/get_public_waitlist', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  verificationCreate = async (phoneNumber) => {
    if (!phoneNumber) {
      throw new Error('Phone number is required');
    }
    return fetch(this.API_URL + '/verification_create', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: phoneNumber.trim(),
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
      }
      return response.json();
    })
    .catch(err => {
      console.log('Error', err);
    });
  }

  verificationCheck = async (phoneNumber, code, referralId, email) => {
    let obj = {
      phone_number: phoneNumber,
      verification_code: code,
      referral_id: undefined,
      tcpa_consent: 'granted',
    };
    if (referralId) {
      obj.referral_id = referralId;
    }
    if (email) {
      obj.email = email;
    }
    return fetch(this.API_URL + '/verification_check', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(obj),
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  acceptTerms = async () => {
    if (!await this.auth.isAuthenticated()) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    return fetch(this.API_URL + '/update_sms_consent', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        tcpa_consent: 'granted',
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  getUser = async () => {
    if (!await this.auth.isAuthenticated()) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    return fetch(this.API_URL + '/get_user', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  postSurveyComplete = async (responseId) => {
    if (!await this.auth.isAuthenticated() || !responseId) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    return fetch(this.API_URL + '/post_survey_data', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        typeform_response_id: responseId,
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  postUserInfo = async (firstName, lastName, email) => {
    if (!await this.auth.isAuthenticated() || !firstName || !lastName || !email) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    return fetch(this.API_URL + '/post_survey_data', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        info: true,
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        first_name: firstName,
        last_name: lastName,
        email: email,
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  // handle as "siscoe", without the @
  registerTwitterHandle = async (handle) => {
    if (!await this.auth.isAuthenticated() || !handle) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    return fetch(this.API_URL + '/register_external_account', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        handle_type: 'twitter',
        handle: handle.trim(),
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  // handle as "siscoe", without the @
  registerInstagramHandle = async (handle) => {
    if (!await this.auth.isAuthenticated() || !handle) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    console.log({
      phone_number: authInfo.phoneNumber,
      auth_token: authInfo.token,
      handle_type: 'instagram',
      handle: handle.trim(),
    })
    return fetch(this.API_URL + '/register_external_account', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        handle_type: 'instagram',
        handle: handle.trim(),
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  initiateAsyncCompletion = async (taskId) => {
    if (!await this.auth.isAuthenticated() || !taskId) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    console.log({
      phone_number: authInfo.phoneNumber,
      auth_token: authInfo.token,
      task_id: taskId,
    });
    return fetch(this.API_URL + '/initiate_async_completion', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        task_id: taskId,
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }

  initiateTwitterCheck = async (handle, taskId) => {
    if (!await this.auth.isAuthenticated() || !handle || !taskId) {
      return null;
    }
    const authInfo = await this.auth.getAuthInfo();
    return fetch(this.API_URL + '/initiate_check_twitter_completion', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        phone_number: authInfo.phoneNumber,
        auth_token: authInfo.token,
        twitter_handle: handle.trim(),
        task_id: taskId,
      })
    }).then(response => {
      if (!response.ok) {
        console.log('response was not a 200-299 status code');
        this.auth.isAccessTokenInvalid(response.json());
      }
      return response.json();
    }).catch(err => {
      console.log('Error', err);
    });
  }
}
