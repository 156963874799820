import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../constants/themes';

const Type = ({ children, style, component = 'p', type = 'p', alignLeft, alignCenter, alignRight, maxHeight, renderAsHTML = false, }) => {
  const theme = useContext(ThemeContext);

  const getTypeSpecificStyles = () => {
    switch (type) {
      case 'h1':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(28px + 0.8vw)',
          fontWeight: 700,
          color: theme.text
        };
      case 'h2':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(20px + 0.625vw)',
          fontWeight: 700,
          color: theme.text
        };
      case 'h3':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(16px + 0.5vw)',
          fontWeight: 600,
          color: theme.text
        };
      case 'p':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(14.5px + 0.4vw)',
          fontWeight: 400,
          color: theme.text
        };
      case 'subtle':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(14.5px + 0.4vw)',
          fontWeight: 400,
          color: theme.subtle
        };
      case 'caption':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(13px + 0.375vw)',
          fontWeight: 400,
          color: theme.subtle
        };
      case 'caption-sm':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(11px + 0.35vw)',
          fontWeight: 400,
          color: theme.subtle
        };
      case 'label':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(14.5px + 0.4vw)',
          fontWeight: 600,
          color: theme.subtle,
          textTransform: 'uppercase'
        };
      case 'value':
        return {
          fontFamily: 'Montserrat',
          fontSize: 'calc(16px + 0.45vw)',
          fontWeight: 400,
          color: theme.text
        };
      default:
        return {};
    }
  }

  const styles = {
    text: {
      fontFamily: 'Arial, Helvetica',
      ...getTypeSpecificStyles(),
      textAlign: alignRight ? 'right' : (alignLeft ? 'left' : (alignCenter ? 'center' : undefined)),
      maxHeight,
      overflow: maxHeight ? 'scroll' : undefined,
      ...style
    }
  };

  const TypeComponent = component;
  if (renderAsHTML) {
    return (
      <TypeComponent
        className={type}
        style={styles.text}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  }
  return (
    <TypeComponent
      className={type}
      style={styles.text}
    >
      {children}
    </TypeComponent>
  );
}

Type.propTypes = {
   children: PropTypes.any,
   type: PropTypes.oneOf(['h1', 'h2', 'h3', 'p', 'subtle', 'caption', 'label', 'value'])
};

export default Type;
