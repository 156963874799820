import React, { createContext, useReducer, } from 'react';
import PropTypes from 'prop-types';
import Crescent from '../api/crescent';

const crescent = new Crescent();

const initialState = {
  error: undefined
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      // from get_user
      case 'SET_DATA': {
        const newState = { ...state, ...action.payload, };
        return newState;
      }
      case 'SET_TWITTER_HANDLE': {
        const newState = { ...state, twitter_handle: action.payload, };
        return newState;
      }
      case 'SET_INSTAGRAM_HANDLE': {
        const newState = { ...state, instagram_handle: action.payload, };
        return newState;
      }
      case 'THROW_ERROR': {
        console.log('error', action.payload);
        const newState = { ...state, error: action.payload, };
        return newState;
      }
      default: {
        throw new Error();
      }
    };
  }, initialState);

  // Include anything in the provider state from props
  const providerState = {...state};
  return <Provider value={{ state: providerState, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { store, StateProvider };