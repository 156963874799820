import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../constants/themes';
import Loader from '../../components/atoms/Loader';
import Type from '../../components/atoms/Type';
import Button from '../../components/atoms/Button';
import NavBar from '../../components/molecules/NavBar';
import Auth from '../../api/auth';
import style from './index.module.css';
import { Analytics, useLocationChange } from '../../utils';

const auth = new Auth();
  
const Settings = (props) => {
  const theme = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const signOut = async () => {
    await auth.signOut();
    props.history.push('/');
    Analytics.trackSignedOut();
  }

  useLocationChange(() => {
    Analytics.page('Settings');
  });

  return (
    <>
      <div className={`${style.loaderBackground} ${!isLoading ? style.fadeOut : ''}`}>
        <Loader />
      </div>
      <div className={style.background} style={{backgroundColor: theme.body, }}>
        <NavBar history={props.history} showAccount={false} showDashboard />
        <div className={style.content}>
          <div className={style.header}>
            <Type type="h1" component="h1">Settings</Type>
          </div>
          <Type>Much more to come, very soon.</Type>
          <Type style={{ marginTop: 0, }}>- Crescent Team</Type>
          <br />
          {/* TODO: Add a link to contact support */}
          <div>
            <Button value="Sign Out" size="sm" onClick={signOut} />
          </div>
        </div>
      </div>
    </>
  );
}
  
export default Settings;
  