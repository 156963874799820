import { useEffect, useState, } from 'react';
import { useLocation } from 'react-router';

// 500 -> 5.0%
export const getAPYStringFromAPINumber = (number) => {
  if (!number) return null;
  return `${parseInt(number / 100).toFixed(1)}%`;
}

export const parseIfPossible = (str) => {
  try {
    return JSON.parse(JSON.stringify(str));
  } catch (e) {
    return {};
  }
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const useLocationChange = (action) => {
  const location = useLocation();
  useEffect(() => { 
    action(location);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}

export class Analytics {

  static page = (path = '/') => {
    window.analytics.page(path, {}, {
      Intercom: { hideDefaultLauncher: true }
    });
  }

  static identify = (identifier = null, traits = {}) => {
    identifier && window.analytics.identify(identifier, {
      phone: identifier,
      signupSource: 'waitlist',
      ...traits
    });
  }

  static trackSignedUp = (properties = {}) => {
    window.analytics.track('Signed Up', properties);
  }

  static trackSignedIn = (properties = {}) => {
    window.analytics.track('Signed In', properties);
  }

  static trackSignedOut = (properties = {}) => {
    window.analytics.track('Signed Out', properties);
    window.analytics.reset();
  }

  static trackAccountCreated = (properties = {}) => {
    window.analytics.track('Account Created', properties);
  }

  static trackVerificationCreated = (properties = {}) => {
    window.analytics.track('Verification Created', properties);
  }

  static trackVerificationCompleted = (properties = {}) => {
    window.analytics.track('Verification Completed', properties);
  }

  static trackVerificationFailed = (properties = {}) => {
    window.analytics.track('Verification Failed', properties);
  }

  static trackMissionViewed = (properties = {}) => {
    window.analytics.track('Mission Viewed', properties);
  }

  static trackMissionActivated = (properties = {}) => {
    window.analytics.track('Mission Activated', properties);
  }

  static trackMissionCompleted = (properties = {}) => {
    window.analytics.track('Mission Completed', properties);
  }

  static trackMissionFailed = (properties = {}) => {
    window.analytics.track('Mission Failed', properties);
  }
}