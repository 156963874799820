import React, { useContext, } from 'react';
import { ThemeContext } from '../../../../constants/themes';
import { store } from '../../../../utils/store';
import { parseIfPossible, } from '../../../../utils';
import Card from '../../../../components/atoms/Card';
import Type from '../../../../components/atoms/Type';
import Button from '../../../../components/atoms/Button';
import { ReactComponent as RepeatIcon } from '../../../../assets/img/repeat.svg';
import { ReactComponent as DangerIcon } from '../../../../assets/img/danger.svg';
import { ReactComponent as InformationIcon } from '../../../../assets/img/information.svg';
import { ReactComponent as CompleteIcon } from '../../../../assets/img/complete.svg';
import { ReactComponent as PointIcon } from '../../../../assets/img/point.svg';
import { ReactComponent as CaretRight } from '../../../../assets/img/caret-right.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/img/arrow-left.svg';
import style from './index.module.css';

const TwitterSlotDetails = ({ mission, goBack, startMission, reloadData, isReloadingData, }) => {
  const theme = useContext(ThemeContext);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const hasReachedAllowedCompletions = mission.completion_limit !== -1 && mission.times_completed >= mission.completion_limit;
  const missionAsyncStatus = parseIfPossible(state.async_task_status)[mission.task_id];

  const nextDurationReward = state.duration_reward_ladder?.sort((a, b) => a.points_required - b.points_required)?.find((r) => r.points_required > state.points);
  let nextDurationRewardWithinReach;
  if (nextDurationReward && nextDurationReward.points_required <= mission.point_reward) {
    nextDurationRewardWithinReach = nextDurationReward;
  }

  const nextPossibleRankProgress = state.rank_progression?.reduce((temp, rp) => {
    if (rp.points_required <= mission.point_reward && rp.points_required <= (temp?.points_required || Number.MAX_SAFE_INTEGER)) return rp;
    return temp;
  }, null);

  return (
    <>
      <div className={style.topSection}>
        <div className={style.header}>
          <br />
          <Button type="wrapper" onClick={goBack}>
            <div className={style.backButton}>
              <ArrowLeft width={18} height={18} style={{ marginTop: '4px', }} />
            </div>
          </Button>
        </div>
        <div className={style.missionDetails}>
          <div className={style.missionEmoji}>
            <span>{mission.emoji}</span>
          </div>
          <div className={style.missionInfo}>
            <Type type="h1" component="h1" style={{ marginBottom: '12px', }}>{mission.name}</Type>
            <Type style={{ marginTop: 0, }}>{mission.short_description}</Type>
            <br />
            <Card type="special" style={{ width: '100%', margin: 0, padding: '0 8px', }}>
              <div className={style.statusRow}>
                <div>
                  <Type type="h3" style={{ marginBottom: 0, }}>+ ???</Type>
                  <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '6px', }}>Crescent Points</Type>
                </div>
                {nextPossibleRankProgress && (
                  <>
                    <div className={style.vc} style={{ backgroundColor: theme.text, }} />
                    <div>
                      <Type type="h3" style={{ marginBottom: 0, }}>+{state.rank - nextPossibleRankProgress.rank_achievement}</Type>
                      <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '6px', }}>spots in line</Type>
                    </div>
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
        <div className={style.bottomSection} style={{ backgroundColor: theme.body, }}>
          <div className={style.bottomSectionContent}>
            <br />
            <br />
            {mission.long_description && (
              <>
                <Type style={{ marginTop: 0, marginBottom: '0.5em', fontWeight: 600, }}>Detailed Description</Type>
                {mission.long_description.split('<br>').map((line, i) => (
                  <Type style={{ marginTop: 0, }} key={`${line}-${i}`}>{line}</Type>
                ))}
              </>
            )}
            {/* <Type type="label" style={{ color: theme.text, }}>The Reward</Type>
            <Type style={{ margin: 0, }}>Earn <PointIcon width={22} height={22} className={style.point} /> <b>{mission.point_reward}</b>{(nextPossibleRankProgress || nextDurationRewardWithinReach) && (', which is enough to...')}</Type>
            <ul className={style.ul}>
              {nextDurationRewardWithinReach && (
                <li className={style.li}><Type style={{ margin: 0, }}><strong>•</strong> Qualify for <b>{nextDurationRewardWithinReach.duration_reward} month{nextDurationRewardWithinReach.duration_reward !== 1 ? 's' : ''}</b> of 6% APY</Type></li>
              )}
              {nextPossibleRankProgress && (
                <li className={style.li}><Type style={{ margin: 0, }}><strong>•</strong> Jump to <b>#{nextPossibleRankProgress.rank_achievement}</b> on the waitlist</Type></li>
              )}
            </ul> */}
            <div className={style.missionActionsWrapper}>
              <Button
                value={'Spin to Win'}
                onClick={startMission}
                style={{ width: '100%', }}
                // Uncomment to test despite completion
                disabled={hasReachedAllowedCompletions}
              />
            </div>
            <br />
            <br />
            {(missionAsyncStatus || mission.times_completed > 0) && (
              <>
                <hr />
                <Type type="label" style={{ color: theme.text, marginBottom: 0, }}>Past Spins</Type>
                <ul className={style.ul}>
                  {missionAsyncStatus?.status === 'pending' && (<li className={style.li}>
                    <Type style={{ margin: 0, }}><strong>•</strong> Recent Spin Processing...</Type>
                    {/* <Button type="secondary" value="Refresh" size="sm" onClick={reloadData} disabled={isReloadingData} style={{ margin: '0 0.5em' }} /> */}
                  </li>)}
                  {missionAsyncStatus?.status === 'failed' && (<li className={style.li}><Type style={{ margin: 0, }}><strong>•</strong> Recent Attempt Failed - {missionAsyncStatus.readable_error || 'Please try again'}</Type></li>)}
                  {mission.data?.actual_points_earned?.slice().reverse().map(earning => (
                    <li className={style.li}><Type style={{ margin: 0, }}><strong>•</strong> Wagered 25, won {earning}.</Type></li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default TwitterSlotDetails;