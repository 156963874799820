import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { store } from '../../../utils/store';
import Loader from '../../../components/atoms/Loader';
import Type from '../../../components/atoms/Type';
import TextInput from '../../../components/atoms/TextInput';
import Button from '../../../components/atoms/Button';
import emojiNameScreenshot from '../../../assets/img/emoji-name-screenshot.png';
import Crescent from '../../../api/crescent';
import style from './index.module.css';

const crescent = new Crescent();

const REFRESH_POST_COMPLETION_DELAY = 2000;

const Step = ({ activeStep, step, children, visible = (step) => step === activeStep, }) => {
  return (
    <div className={style.step} style={visible(step) ? undefined : { opacity: 0.1, pointerEvents: 'none', }}>
      <div className={style.stepNumber}>
        <Type style={{ fontWeight: 600, margin: 0, }}>{step}.</Type>
      </div>
      {children}
    </div>
  )
}

const TwitterNameEmoji = ({ mission, onMissionComplete, }) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const stepTwoRef = useRef(null);

  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    if (!!state.twitter_handle && activeStep !== 2) {
      setActiveStep(2);
      if (stepTwoRef.current) {
        stepTwoRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ state.twitter_handle, activeStep, ]);

  const [processingMissionComplete, setProcessingMissionComplete] = useState(false);

  const _onMissionComplete = () => {
    setProcessingMissionComplete(true);
    // If actually on the last step...
    if (activeStep === 2) {
      // First check if there's already a pending check for this mission in async_task_status
      // If there is, just reload things by "completing" the mission
      if (state.async_task_status[mission.id] && state.async_task_status[mission.id].status === 'pending') {
        setTimeout(() => {
          onMissionComplete(mission.task_id, mission.task_type);
          setProcessingMissionComplete(false);
        }, REFRESH_POST_COMPLETION_DELAY);
      }
      crescent.initiateAsyncCompletion(mission.task_id).then(res => {
        console.log('emoji check response', res);
        if (res && !res.error) {
          try {
            setTimeout(() => {
              onMissionComplete(mission.task_id, mission.task_type);
              setProcessingMissionComplete(false);
            }, REFRESH_POST_COMPLETION_DELAY);
          } catch (err) {
            console.log(err);
            onMissionComplete(mission.task_id, mission.task_type, { error: err });
            setProcessingMissionComplete(false);
          }
        } else {
          console.log(res?.err);
          onMissionComplete(mission.task_id, mission.task_type, { error: res?.err });
          setProcessingMissionComplete(false);
        }
      });
    }
  }

  const [twitterHandle, setTwitterHandle] = useState('');

  const onTwitterHandleChange = useCallback((e) => {
    setTwitterHandle(e.target.value);
  }, []);

  const [submittingTwitterHandle, setSubmittingTwitterHandle] = useState(false);

  const submitTwitterHandle = () => {
    setSubmittingTwitterHandle(true);
    if (twitterHandle && twitterHandle.length > 3) {
      let _twitterHandle = twitterHandle.trim().replace(/[@&/\\#,+()$~%.'":*?<>{}]/g, '');
      crescent.registerTwitterHandle(_twitterHandle).then((res) => {
        if (res && !res.error) {
          dispatch({
            type: 'SET_TWITTER_HANDLE',
            payload: twitterHandle,
          });
          setActiveStep(2);
          setSubmittingTwitterHandle(false);
        } else {
          // TODO: Handle error state where twitter handle submission fails
          // i.e. twitter handle already exists?
        }
      });
    }
  }

  return (
    <>
      <Step activeStep={activeStep} step={1}>
        <Type type="h2" component="h2" style={{ marginBottom: '12px', }}>Enter your Twitter account handle</Type>
        <br />
        <TextInput
          label="Twitter Username"
          name="twitterHandle"
          placeholder="username"
          format="twitterHandle"
          width={'100%'}
          value={state.twitter_handle ? `@${state.twitter_handle}` : twitterHandle}
          onChange={onTwitterHandleChange}
        />
        <br />
        <Button type="secondary" value="Next" onClick={submitTwitterHandle} disabled={submittingTwitterHandle || !!state.twitter_handle} />
      </Step>
      <Step activeStep={activeStep} step={2}>
        <div ref={stepTwoRef} />
        <Type type="h2" component="h2" style={{ marginBottom: '12px', }}>Add a 🌙 emoji to your Twitter profile name</Type>
        <img src={emojiNameScreenshot} alt="emoji in name screenshot" className={style.helpfulImg}/>
        <Type>You'll be rewarded once we verify the emoji is part of your Twitter name. Emoji must remain in your name to keep the mission reward points.</Type>
        <br />
        <Button type="secondary" style={{ textDecoration: 'underline', }} value={`https://www.twitter.com/${state.twitter_handle}`} href={`https://www.twitter.com/${twitterHandle}`} openInNewTab />
        <br />
        <br />
        {processingMissionComplete && (
          <div className={style.processingMsg}>
            <div style={{ display: 'inline-block', marginRight: '8px', }}>
              <Loader />
            </div>
            <Type>Processing...</Type>
          </div>
        )}
        <br />
        <Button type="primary" value="Done" onClick={_onMissionComplete} disabled={processingMissionComplete} />
      </Step>
    </>
  );
}

export default TwitterNameEmoji;