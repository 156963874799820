import React, { useContext, } from 'react';
import Confetti from 'react-confetti';
import { ThemeContext } from '../../../../constants/themes';
import Card from '../../../../components/atoms/Card';
import Type from '../../../../components/atoms/Type';
import Button from '../../../../components/atoms/Button';
import { ReactComponent as PointIcon } from '../../../../assets/img/point.svg';
import style from './index.module.css';
  
const PostJoin = (props) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      {(props.info && !props.info?.isExistingUser) && <Confetti recycle={false} />}
      <div className={style.bottom}>
        <div className={style.description}>
          <br />
          {props.info && props.info?.isExistingUser
            ? (
              <Type style={{ marginBottom: '0.5em', }} alignCenter>You know the drill. Earn <PointIcon width={18} height={18} className={style.inlinePoint} /> points to move up the waitlist and <strong>increase your future Crescent APY</strong>.</Type>
            ) : (
              <>
                <Card type="inset">
                  <div className={style.rewardCard}>
                    <Type type="h1" style={{ marginBottom: 0, marginTop: '0.2em'}}><PointIcon width={32} height={32} className={style.point} />100</Type>
                    <Type style={{ marginTop: '0.5em', marginBottom: '0.5em', }}>Earned Points</Type>
                    <hr />
                    <Type type="caption" style={{ marginTop: '0.5em', marginBottom: '0.7em', textTransform: 'uppercase', fontWeight: 600, color: theme.text, }}>You unlocked <span style={{ color: theme.success, }}>6% APY for 1 month</span> 🎉</Type>
                  </div>
                </Card>
                <br />
                <Type style={{ marginBottom: '0.5em', }} alignCenter>You earned your first 100 points. <PointIcon width={18} height={18} className={style.inlinePoint} /> Points move you up the waitlist and <strong>increase your future Crescent APY</strong>.</Type>
              </>
            )}
        </div>
        <div className={style.actionFooter} style={{ backgroundColor: theme.body, }}>
          <div style={{ margin: 'auto', maxWidth: '20em', }}>
            <Button
              value="Let's Go"
              onClick={props.goToMissions}
              type="primary"
              style={{ width: '100%', }}
            /> 
          </div>
        </div>
      </div>
    </>
  );
}
  
export default PostJoin;
  