import React from 'react';

export const themes = {
   light: {
     body: '#eee',
     text: '#04001A',
     subtle: 'rgba(0, 0, 0, 0.6)',
     card: 'rgba(255, 255, 255, 0.2)',
     verySubtle: 'rgba(255, 255, 255, 0.06)',
     white: '#eee',
     black: '#000',
     primary: '#6A00D5',
     secondary: '#3CF3FF',
     danger: '#FF3C78',
     warning: '#F3EA05',
     success: '#01D696',
   },
   dark: {
      body: '#04001A',
      text: '#eee',
      subtle: 'rgba(255, 255, 255, 0.6)',
      card: 'rgba(255, 255, 255, 0.2)',
      verySubtle: 'rgba(255, 255, 255, 0.06)',
      white: '#eee',
      black: '#000',
      primary: '#6A00D5',
      secondary: '#3CF3FF',
      danger: '#FF3C78',
      warning: '#F3EA05',
      success: '#01D696',
   },
 };

 export const ThemeContext = React.createContext(
   themes.light // default value
 );