import React, { useCallback, useContext, useEffect, useState, } from 'react';
import { ThemeContext } from '../../../../constants/themes';
import Type from '../../../../components/atoms/Type';
import Button from '../../../../components/atoms/Button';
import TextInput from '../../../../components/atoms/TextInput';
import Loader from '../../../../components/atoms/Loader';
import Crescent from '../../../../api/crescent';
import style from './index.module.css';
import { Analytics } from '../../../../utils';

const crescent = new Crescent();

const VerificationCreate = (props) => {
  const theme = useContext(ThemeContext);

  const [formInputs, setFormInputs] = useState({
    phoneNumber: '',
  });
  const [validation, setValidation] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const sanitizePhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '').trim();
  }

  const validate = (phoneNumber) => {
    let validation = [];
    if (!phoneNumber) {
      validation.push("Please enter a phone number.");
    // TODO: This check will only work for US numbers.
    } else if (phoneNumber.length !== 12) {
      validation.push("Please enter a valid phone number.");
    }
    return validation;
  }

  // @param {string} phoneNumber: Phone number coming from external site
  const submit = async (externalPhoneNumber) => {
    setValidation(null);
    setIsProcessing(true);
    const phoneNumber = externalPhoneNumber || `+1${sanitizePhoneNumber(formInputs.phoneNumber)}`;
    const validation = validate(phoneNumber);
    if (validation.length === 0) {
      // TODO: Support non-US phone numbers!
      crescent.verificationCreate(phoneNumber).then(res => {
        if (!!res && !!res.phone_number) {
          props.setInfo({
            ...props.info,
            externalPhoneNumber: null,
            phoneNumber: res.phone_number,
            isExistingUser: res.existing_user,
            tcpaGranted: res.tcpa_granted,
          });
          props.stopLoading();
          props.nextStep();
          Analytics.trackVerificationCreated({
            phone: res.phone_number
          });
        } else {
          setValidation(['Sorry, there was an error. Please try again.']);
          setIsProcessing(false);
        }
      });
    } else {
      setValidation(validation);
      setIsProcessing(false);
    }
  }

  const onFormKeyDown = (e) => {
    if (e.key === 'Enter') {
      submit();
    }
  }

  useEffect(() => {
    if (props.info.externalPhoneNumber) {
      submit(props.info.externalPhoneNumber);
    }
  }, [ props.info.externalPhoneNumber, ]);

  const onInputChange = useCallback((e) => {
    setFormInputs(prevFormInputs => ({
      ...prevFormInputs,
      [e.target.name]: e.target.value,
    }));
  }, []);

  return (
    <div className={style.bottom}>
      <div className={style.form} onKeyDown={onFormKeyDown}>
        <Type style={{ color: theme.warning, }}>{validation?.toString()}</Type>
        <TextInput
          label="Phone Number"
          name="phoneNumber"
          placeholder="(123) 456-7890"
          format="phoneNumber"
          width={'100%'}
          prepend={'+1'}
          value={formInputs.phoneNumber}
          onChange={onInputChange}
        />
        {isProcessing && (
          <>
            <br />
            <Loader />
          </>
        )}
      </div>
      <div className={style.actionFooter} style={{ backgroundColor: theme.body, }}>
        <div style={{ margin: 'auto', maxWidth: '20em', }}>
          <Button
            value="Submit"
            onClick={() => submit()}
            type="primary"
            disabled={!formInputs.phoneNumber}
            isProcessing={isProcessing}
            style={{ width: '100%', }}
          />
        </div>
      </div>
    </div>
  );
}

export default VerificationCreate;
