import React, { useContext, useEffect, useRef, useState, } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useSpring, animated, } from 'react-spring';
import SizedConfetti from 'react-confetti';
import Type from '../../atoms/Type';
import { ReactComponent as PointIcon } from '../../../assets/img/point.svg';
import { ReactComponent as CheckIcon } from '../../../assets/img/check.svg';
import { getAPYStringFromAPINumber, useWindowSize, } from '../../../utils';
import style from './index.module.css';
import { ThemeContext, } from '../../../constants/themes';
import AnimatedNumberType from '../AnimatedNumberType';

// Pushes the progress bar to the right so it's visible when you've eclipsed 500 points
const POST_500_MIN_PROGRESS = 0.55;

const Point = ({ displayValue, positionPct, reward, isComplete, }) => {
  const theme = useContext(ThemeContext);

  const left = positionPct * 100;

  return (
    <div className={style.pointWrapper} style={{ left: `${left}%`, }}>
      <div className={style.pointValue}>
        {/* <PointIcon className={style.pointImgSm} width={18} height={18} /> */}
        <Type type="caption" style={{ margin: 0, fontWeight: '600', color: theme.white }}>{displayValue}</Type>
      </div>
      <div className={`${style.dot} ${isComplete ? style.dot_checked : style.dot_unchecked}`}>
        {isComplete
          ? (
            <CheckIcon width={18} height={18} style={{ padding: '1px', }}/>
          ) : (
            <div className={style.dot_inner_incomplete} />
          )}
      </div>
      {/* REWARD LABEL */}
      {/* <div className={style.label}>
        <Type type="caption" style={{ margin: 0, fontWeight: '600', color: theme.white, }}>{reward}</Type>
      </div>
      <div className={`${style.vc}  ${isComplete ? style.vc_complete : style.vc_incomplete}`} /> */}
    </div>
  );
}

const PointsProgressBar = (props) => {
  const theme = useContext(ThemeContext);
  const barRef = useRef();

  const windowSize = useWindowSize();

  // We need an offset so that the 500th point reward is not at the absolute end
  const APY_POINTS_OFFSET = 120;

  const maxValue = Math.max.apply(Math, props.durationRewardLadder?.map((r) => r.points_required));
  const highScore = Math.max(Math.max.apply(Math, props.rankProgression?.map((r) => r.points_required)), 1500);
  
  const [progressPct, setProgressPct] = useState(0);
  const progressPctWidth = useSpring({ width: `${progressPct}%`, });

  const [confettiDims, setConfettiDims] = useState({});

  useEffect(() => {
    if (barRef.current) {
      const barDOM = ReactDOM.findDOMNode(barRef.current).getBoundingClientRect();
      setConfettiDims({ x: barDOM.x, y: barDOM.y - 10, width: barDOM.width, height: barDOM.height, });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barRef.current, progressPct, windowSize.width, windowSize.height]);

  const [scrollLeft, setScrollLeft] = useState(0);
  const confettiLeft = progressPct - (scrollLeft / 10);

  const [showConfetti, setShowConfetti] = useState(false);
  const progressPctConfettiPadding = useSpring({ left: `${confettiLeft}%`, });

  useEffect(() => {
    let timeout;
    if (showConfetti) {
      timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 500);
    }

    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfetti]);

  useEffect(() => {
    if (props.points !== undefined && maxValue) {
      if (props.points <= maxValue) {
        if (props.justCompletedMission) {
          setShowConfetti(true);
        }
        setProgressPct((props.points/(maxValue+APY_POINTS_OFFSET)/2) * 100);
      } else {
        if (props.justCompletedMission) {
          setShowConfetti(true);
        }
        setProgressPct(Math.max((props.points / highScore), POST_500_MIN_PROGRESS) * 100);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.points, maxValue, highScore]);

  const nextDurationReward = props.durationRewardLadder?.sort((a, b) => a.points_required - b.points_required)?.find((r) => r.points_required > props.points);
  const pointsUntilNextReward = nextDurationReward ? nextDurationReward?.points_required - props.points : null;
  const nextReward = nextDurationReward ? nextDurationReward?.duration_reward + props.duration : null;

  return (
    <>
      <animated.div style={{ position: 'absolute', top: -confettiDims.height, ...progressPctConfettiPadding, }}>
        <SizedConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={showConfetti}
          numberOfPieces={showConfetti ? 200 : 0}
          confettiSource={{
            w: 10,
            h: 10,
            x: confettiDims.x + 10,
            y: confettiDims.y + 10,
          }}
        />
      </animated.div>
      <div className={style.wrapper} onScroll={(e) => setScrollLeft(e.target.scrollLeft)}>
        <div className={style.pointsSummary}>
          <div className={style.pointsWrapper}>
            <PointIcon className={style.pointImg} width={30} height={30} />
            <AnimatedNumberType
              type="h2"
              component="h2"
              style={{ margin: '6px 0', }}
              value={props.points}
              formatValue={(value) => value.toFixed(0)}
              duration={500}
              ease='easeInOut'
            />
          </div>
          {pointsUntilNextReward && pointsUntilNextReward > 0 && <Type type="caption" component="p" style={{ color: theme.text, }}><strong>{pointsUntilNextReward} pts</strong> until {nextReward} month{nextReward !== 1 ? 's' : ''} of 6% APY</Type>}
        </div>
        <div className={style.barWrapperWrapper}>
          <div className={style.barWrapper}>
            <div className={style.barOuter}>
              <animated.div className={style.barInner} style={progressPctWidth} ref={barRef} />
            </div>
            <div className={style.points}>
              {props.durationRewardLadder?.map((r, index) => (
                <Point key={`${JSON.stringify(r)}-${index}`} displayValue={r.points_required} positionPct={r.points_required/(maxValue+APY_POINTS_OFFSET)/2} reward={r.duration_reward} isComplete={r.points_required <= props.points} />
              ))}
              {props.rankProgression?.map((r, index) => {
                if (r.points_required > (highScore / 2)) {
                  return <Point key={`${JSON.stringify(r)}-${index}`} displayValue={r.points_required} positionPct={r.points_required/highScore} reward={`#${r.rank_achievement} in line`} isComplete={r.points_required <= props.points} />
                } else {
                  return null;
                }
              })}
              {/* <Point displayValue={highScore} positionPct={1} reward={"???"} isComplete={highScore <= props.points} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PointsProgressBar.propTypes = {
  currentPosition: PropTypes.number,
  points: PropTypes.number,
  durationRewardLadder: PropTypes.array,
  rankProgression: PropTypes.array,
}

export default PointsProgressBar;