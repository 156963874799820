import React, { useContext, useEffect, useRef, useState, } from 'react';
import ReactDOM from 'react-dom';
import SizedConfetti from 'react-confetti';
import { ThemeContext } from '../../../../constants/themes';
import { store } from '../../../../utils/store';
import { parseIfPossible, } from '../../../../utils';
import Card from '../../../../components/atoms/Card';
import Type from '../../../../components/atoms/Type';
import Button from '../../../../components/atoms/Button';
import DateCountdown from '../../components/DateCountdown';
import { ReactComponent as RepeatIcon } from '../../../../assets/img/repeat.svg';
import { ReactComponent as DangerIcon } from '../../../../assets/img/danger.svg';
import { ReactComponent as InformationIcon } from '../../../../assets/img/information.svg';
import { ReactComponent as CompleteIcon } from '../../../../assets/img/complete.svg';
import { ReactComponent as PointIcon } from '../../../../assets/img/point.svg';
import { ReactComponent as CaretRight } from '../../../../assets/img/caret-right.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/img/arrow-left.svg';
import { useWindowSize, } from '../../../../utils';
import style from './index.module.css';

const DroppingSoon = ({ goBack, }) => {
  const theme = useContext(ThemeContext);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const buttonRef = useRef(null);

  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    let timeout;
    if (showConfetti) {
      timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 500);
    }

    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfetti]);

  const [confettiDims, setConfettiDims] = useState({});

  useEffect(() => {
    if (buttonRef.current) {
      const buttonDOM = ReactDOM.findDOMNode(buttonRef.current).getBoundingClientRect();
      setConfettiDims({ x: buttonDOM.x, y: buttonDOM.y - 10, width: buttonDOM.width, height: buttonDOM.height, });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ buttonRef.current, showConfetti, ]);

  const confettiHeight = confettiDims.y - confettiDims.height;

  return (
    <>
      <div className={style.topSection}>
        <div className={style.header}>
          <br />
          <Button type="wrapper" onClick={goBack}>
            <div className={style.backButton}>
              <ArrowLeft width={18} height={18} style={{ marginTop: '4px', }} />
            </div>
          </Button>
        </div>
        <div className={style.missionDetails}>
          <div className={style.missionEmoji}>
            <span>🤖</span>
          </div>
          <div className={style.missionInfo}>
            <Type type="h1" component="h1" style={{ marginBottom: '12px', }}>Prepare for Liftoff</Type>
            <Type style={{ marginTop: 0, }}>New mission dropping Monday</Type>
            <br />
            <Card type="special" style={{ width: '100%', margin: 0, padding: '0 8px', }}>
              <div className={style.statusRow}>
                <div>
                  {/* <Type type="h3" style={{ marginBottom: 0, }}>ETA</Type> */}
                  <Type type="caption" style={{ fontWeight: '600', }}>
                    <DateCountdown
                      dateTo={new Date(('February 28, 2022 12:00:00 CST')).toString()}
                      locales={['y', 'm', 'd', 'hr', 'm', 's']}
                      locales_plural={['y', 'm', 'd', 'h', 'm', 's']}
                      callback={() => console.log('date expired!')}
                    />
                  </Type>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className={style.bottomSection} style={{ backgroundColor: theme.body, }}>
          <div className={style.bottomSectionContent}>
            <br />
            <br />
            <Type style={{ marginTop: 0, marginBottom: '0.5em', fontWeight: 600, }}>A new opportunity to earn <i>hundreds</i> of Crescent waitlist points in <i>seconds</i>.</Type>
            <Type style={{ marginTop: 0, }}>Ready?</Type>
            <div className={style.missionActionsWrapper}>
              <SizedConfetti
                width={width}
                height={height}
                recycle={showConfetti}
                numberOfPieces={showConfetti ? 100 : 0}
                confettiSource={{
                  w: 10,
                  h: 10,
                  x: width / 2,
                  y: confettiHeight,
                }}
                // drawShape={(ctx, x, y, w, h) => {
                //   ctx.font = '40px serif';
                //   ctx.fillText('🌙', 0, 0);
                // }}
                initialVelocityY={20}
                style={{ zIndex: 0, }}
              />
              <div ref={buttonRef}>
                <Button
                  type="primary"
                  size="small"
                  value="I'm ready"
                  onClick={() => setShowConfetti(true)}
                  style={{ width: '100%', }}
                />
              </div>
            </div>
            <br />
            <br />
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default DroppingSoon;