import React from 'react';
import Button from '../../atoms/Button';
import Type from '../../atoms/Type';
import { ReactComponent as CrescentLogo } from '../../../assets/img/logo.svg';
import { ReactComponent as Account } from '../../../assets/img/account.svg';
import Auth from '../../../api/auth';
import { Analytics, } from '../../../utils';
import style from './index.module.css';

const auth = new Auth();

const NavBar = ({ history, showAccount = true, showDashboard = false, }) => {
  const signOut = async () => {
    await auth.signOut();
    history.push('/');
    Analytics.trackSignedOut();
  }

  return (
    <nav className={style.nav}>
      <Button type="wrapper" onClick={() => history.push('/')}>
        <CrescentLogo width={22} height={22} />
      </Button>
      {/* {showAccount && (
        <Button type="wrapper" onClick={() => history.push('/settings')}>
          <Account width={20} height={20} opacity={0.5} />
        </Button>
      )} */}
      {showAccount && (
        <Button value="Sign Out" type="secondary" size="sm" onClick={signOut} />
      )}
      {showDashboard && (
        <Button type="wrapper" onClick={() => history.push('/missions')}>
          <Type style={{ margin: 0, fontWeight: 600, }}>Missions</Type>
        </Button>
      )}
    </nav>
  );
}

export default NavBar;