import React, { useContext, } from 'react';
import { ThemeContext } from '../../../../constants/themes';
import { store } from '../../../../utils/store';
import Modal from '../../../../components/atoms/Modal';
import Card from '../../../../components/atoms/Card';
import Button from '../../../../components/atoms/Button';
import Type from '../../../../components/atoms/Type';
import { ReactComponent as PointIcon } from '../../../../assets/img/point.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/img/arrow-left.svg';
import style from './index.module.css';

const RewardCard = ({ points, label, description, }) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={style.rewardCardWrapper}>
      <Card type="inset">
        <div className={style.rewardCard}>
          <div className={style.rewardPointSection}>
            <PointIcon width={18} height={18} className={style.rewardPoint} />
            <Type type="label" style={{ margin: 0, color: theme.text, }}>{points}</Type>
          </div>
          <div className={style.rewardDescriptionSection}>
            <Type type="caption" style={{ margin: 0, marginBottom: '0.2em', fontWeight: '600', color: theme.text, }}>{label}</Type>
            <Type type="caption" style={{ margin: 0, opacity: 0.8, color: theme.text, }}>{description}</Type>
          </div>
        </div>
      </Card>
    </div>
  )
}

const RewardsListModal = ({ show, close, }) => {
  const theme = useContext(ThemeContext);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const nextDurationReward = state.duration_reward_ladder?.sort((a, b) => a.points_required - b.points_required)?.find((r) => r.points_required > state.points);

  return (
    <Modal show={show}>
      <div className={style.body}>
        <Button type="wrapper" onClick={close}>
          <div className={style.backButton}>
            <ArrowLeft width={18} height={18} style={{ marginTop: '4px', }} />
          </div>
        </Button>
        <Type type="h1" component="h3" style={{ marginTop: '0.5em', marginBottom: '0.8em', }}>My Points</Type>
        <Card type="inset">
          <div className={style.pointsCard}>
            <Type type="h1" style={{ marginTop: '0.4em', marginBottom: 0, }}><PointIcon width={30} height={30} className={style.point} /> {state.points}</Type>
            <Type style={{ marginTop: '0.5em', }}>Crescent Waitlist Points</Type>
            <hr />
            {nextDurationReward && (
              <Type type="caption" style={{ textTransform: 'uppercase', fontWeight: 600, color: theme.text, }}>{nextDurationReward?.points_required - state.points} points until your next reward</Type>
            )}
          </div>
        </Card>
        <br />
        <Type type="h2" component="h4" style={{ marginTop: '0.5em', marginBottom: '0.8em', }}>What points get you</Type>
        {state.t_tag === 'v1'
          ? (
            <>
              <RewardCard
                points={100}
                label={"1 Month of 6% APY"}
                description={"Unlock a single month of 6% APY"}
              />
              <RewardCard
                points={200}
                label={"2 Months of 6% APY"}
                description={"Unlock 2 total months of 6% APY"}
              />
              <RewardCard
                points={300}
                label={"3 Months of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              <RewardCard
                points={400}
                label={"4 Months of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              <RewardCard
                points={500}
                label={"6 Months of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              <RewardCard
                points={800}
                label={"1 Year of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              {/* Non Duration Related */}
              <RewardCard
                points={2000}
                label={"Golden Ticket to App"}
                description={"Receive an invite to join the Crescent app early. *iPhone only, must be 18+"}
              />
              <RewardCard
                points={3000}
                label={"We'll Deposit $500 in Your Account"}
                description={"You heard us right. We’ll put $500 in your account once your referrals successfully join the app and make their first deposit."}
              />
            </>
          ) : (
            <>
              <RewardCard
                points={100}
                label={"1 Month of 6% APY"}
                description={"Unlock a single month of 6% APY"}
              />
              <RewardCard
                points={200}
                label={"2 Months of 6% APY"}
                description={"Unlock 2 total months of 6% APY"}
              />
              <RewardCard
                points={300}
                label={"3 Months of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              <RewardCard
                points={400}
                label={"6 Months of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              <RewardCard
                points={500}
                label={"1 Year of 6% APY"}
                description={"Unlock when referrals join the app and make their first deposit."}
              />
              {/* Non Duration Related */}
              <RewardCard
                points={1000}
                label={"Golden Ticket to App"}
                description={"Receive an invite to join the Crescent app early. *iPhone only, must be 18+"}
              />
              <RewardCard
                points={2000}
                label={"We'll Deposit $500 in Your Account"}
                description={"You heard us right. Get 2000 points and we'll put $500 in your account once you sign up."}
              />
            </>
          )}
      </div>
    </Modal>
  );
}

export default RewardsListModal;