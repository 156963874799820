import React from 'react';
import Referral from './Referral';
import Survey from './Survey';
import TwitterRetweet from './TwitterRetweet';
import TwitterNameEmoji from './TwitterNameEmoji';
import InstagramGiveaway from './InstagramGiveaway';
import TwitterSlot from './TwitterSlot';
import Type from '../../../components/atoms/Type';
import style from './index.module.css';

const Error = (
  <Type>There was an error starting this mission. Please try again later.</Type>
);

const SpecificMission = ({ mission, onMissionComplete, }) => {
  if (mission) {
    switch(mission.task_type) {
      case 'standard_referral':
        return <Referral mission={mission} onMissionComplete={onMissionComplete} />
      case 'survey_complete':
        return <Survey mission={mission} onMissionComplete={onMissionComplete} />
      case 'tweet_retweet':
        return <TwitterRetweet mission={mission} onMissionComplete={onMissionComplete} />
      case 'twitter_name_emoji':
        return <TwitterNameEmoji mission={mission} onMissionComplete={onMissionComplete} />
      case 'instagram_giveaway':
        return <InstagramGiveaway mission={mission} onMissionComplete={onMissionComplete} />
      case 'twitter_slot':
        return <TwitterSlot mission={mission} onMissionComplete={onMissionComplete} />
      default:
        return Error;
    }
  } else {
    return Error;
  }
}

const Mission = (props) => {
  return (
    <div className={style.missionWrapper}>
      <SpecificMission {...props} />
    </div>
  );
}

export default Mission;