const phoneNumber = (str) => {
  var x = str.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}

const twitterHandle = (str) => {
  return `@${str.trim().replace(/[@&/\\#,+()$~%.'":*?<>{}]/g, '')}`;
}

const instagramHandle = (str) => {
  return `@${str.trim().replace(/[@&/\\#,+()$~%'":*?<>{}]/g, '')}`;
}

module.exports = {
  phoneNumber,
  twitterHandle,
  instagramHandle,
}