import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import style from './index.module.css';

const COPY_TIMEOUT = 1000;

const CopyToClipboardField = ({ value, onCopy = () => {}, }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const inputRef = useRef(null);

  const copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess(true);
  };

  useEffect(() => {
    let timeout;
    if (copySuccess) {
      timeout = setTimeout(() => {
        onCopy(value);
        setCopySuccess(false);
      }, COPY_TIMEOUT);
    }
    return () => clearTimeout(timeout);
  }, [ copySuccess, onCopy, value, ]);

  return (
    <div className={style.wrapper}>
      <input
        ref={inputRef}
        value={value}
        readOnly
      />
      <Button
        size="sm"
        type="secondary"
        value={!copySuccess ? 'Copy' : 'Copied!'}
        disabled={copySuccess}
        onClick={copyToClipboard}
      />
    </div>
  );
}

export default CopyToClipboardField;