import React, { useEffect, useState } from 'react';
import { default as FacebookPixel } from 'react-facebook-pixel';
import { default as SnapchatPixel } from 'react-snapchat-pixel';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeContext, themes } from './constants/themes';
import { StateProvider } from './utils/store';
import Join from './views/join';
import Dashboard from './views/dashboard';
import Settings from './views/settings';
import NukeAccount from './views/nukeaccount';
import Auth from './api/auth';

const auth = new Auth();

const App = () => {
  const [state] = useState({
    theme: themes.dark
  });

  useEffect(() => {
    const track = async () => {
      FacebookPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, undefined, {
        autoConfig: true,
        debug: false,
      });
      FacebookPixel.pageView();
      const authInfo = await auth.getAuthInfo();
      SnapchatPixel.init(process.env.REACT_APP_SNAPCHAT_PIXEL_ID, { user_phone_number: authInfo?.phoneNumber, }, {
        autoConfig: true,
        debug: false,
      });
      SnapchatPixel.pageView();
    }

    track();
  }, []);

  return (
    <StateProvider>
      <div style={{backgroundColor: state.theme.body, minHeight: '100vh'}}>
        <ThemeContext.Provider value={state.theme}>
          <Router>
            <Route
              path="/"
              render={({ location }) => {
                window.gtag('config', process.env.REACT_APP_GOOGLE_ADS_ID, {
                  page_path: location.pathname + location.search,
                });
              }}
            />
            <Switch>
              <Route exact path='/' render={(props) => <Join {...props}/>} />
            </Switch>
            <Switch>
              <Route exact path='/missions' render={(props) => <Dashboard {...props}/>} />
            </Switch>
            <Switch>
              <Route exact path='/settings' render={(props) => <Settings {...props}/>} />
            </Switch>
            <Switch>
              <Route exact path='/nukeaccount' render={(props) => <NukeAccount {...props}/>} />
            </Switch>
          </Router>
        </ThemeContext.Provider>
      </div>
    </StateProvider>
  );
}

export default App;
